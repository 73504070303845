import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, BreadcrumbItem } from "reactstrap";
import FeatherIcon from "feather-icons-react";
import "./BreadcrumbWithFilter.scss";
import { BreadcrumbProps } from "src/models/components/breadcrumbWithFilterModel";
import Button from "src/components/UI/Button";
import { AnimatePresence } from "framer-motion";
import BulkActionStatus from "../BulkActionStatus/BulkActionStatus";

const emptyFunction = () => {

};

const BreadcrumbWithFilter = ({ breadcrumbItem, title, onFilterClick = emptyFunction, onCreateClick = emptyFunction, filter = false, isShowFilter = true, isShowCreate = true, isShowPrint = false, onPrintClick = emptyFunction, isShowFirstButton = false, isShowSecondButton = false, isShowThirdButton = false, isShowForthButton = false, isShowFifthButton = false,
  isShowSithButton = false, isShowSeventhButton = false, isShowEighthButton = false, isShowNinethButton = false, isShowTenthButton = false,
  isShowEleventhButton = false, isShowTwelvethButton = false, isShowThirteenthButton = false, isShowFourteenthButton = false, isShowFifteenthButton = false, isShowSeventeenthButton = false, isShowSixteenthButton = false,isShowEighteenthButton=false,isShownineteenthButton=false,isShowTwenteenthButton=false,isShowTwentyOneButton=false,
  customClassName = "",
  titlePath = "",
  firstButtonLabel = "", secondButtonLabel = "", thirdButtonLabel = "", forthButtonLabel = "", fifthButtonLabel = "",
  sixthButtonLabel = "", seventhButtonLabel = "", eighthButtonLabel = "", ninethButtonLabel = "", tenthButtonLabel = "", eleventhButtonLabel = "",
  twelvethButtonLabel = "", thirteenthButtonLabel = "", fourteenthButtonLabel = "", fifteenthButtonLabel = "", sixteenthButtonLabel = "", seventeenthButtonLabel = "",eighteenthButtonLabel="",nineteenthButtonLabel="",TwenteenthButtonLabel="",TwentyOneButtonLabel="",

  onFirstButtonClick = emptyFunction,
  onSecondButtonClick = emptyFunction, onThirdButtonClick = emptyFunction, onForthButtonClick = emptyFunction, onFifthButtonClick = emptyFunction, onSixthButtonClick = emptyFunction, onSeventhButtonClick = emptyFunction,
  onEighthButtonClick = emptyFunction, onNinethButtonClick = emptyFunction, onTenthButtonClick = emptyFunction, onEleventhButtonClick = emptyFunction, onTwelvethButtonClick = emptyFunction, onThirteenthButtonClick = emptyFunction, onFourteenthButtonClick = emptyFunction, onFifteenthButtonClick = emptyFunction, onSixteenthButtonClick = emptyFunction, onSeventeenthButtonClick = emptyFunction,onEighteenthButtonClick=emptyFunction, onNineteenthButtonClick = emptyFunction,onTwenteenthButtonClick=emptyFunction,onTwentyOneButtonClick=emptyFunction,

  selectedCustomers = [], bulkActionSatusTitle = "", showBorderRight = false, btnClassName = "",
  firstButtonDisabled = false, secondButtonDisabled = false, thirdButtonDisabled = false, forthButtonDisabled = false, fifthButtonDisabled = false, sithButtonDisabled = false, seventhButtonDisabled = false, eighthButtonDisabled = false, ninethButtonDisabled = false, tenthButtonDisabled = false, eleventhButtonDisabled = false, twelvethButtonDisabled = false, thirteenthButtonDisabled = false, fourteenthButtonDisabled = false, fifteenthButtonDisabled = false,
  sixteenthButtonDisabled = false, seventeenthButtonDisabled = false,eighteenthButtonDisabled=false,nineteenthButtonDisabled = false,TwenteenthButtonDisabled=false,TwentyOneButtonDisabled=false,

}: BreadcrumbProps) => {
  return (
    //   <div id="bread-crumb-with-filter" className="page-title-right">
    //   <ol className={`breadcrumb m-0 ${showBorderRight?"br-rg":""}`} >
    //     <BreadcrumbItem>
    //       <Link to={titlePath ? titlePath : "#"}>{title}</Link>
    //     </BreadcrumbItem>
    //     <BreadcrumbItem active>
    //       {/* <Link to="#"> */}
    //       {breadcrumbItem}
    //       {/* </Link> */}
    //     </BreadcrumbItem>
    //   </ol>
    //   {/* <AnimatePresence> {selectedCustomers.length > 0 && <BulkActionStatus count={selectedCustomers.length} title={bulkActionSatusTitle}></BulkActionStatus>}</AnimatePresence> */}
    // </div>



    <Row id="bread-crumb-with-filter">
      <Col xs={12}>
        <div className=" d-sm-flex align-items-center justify-content-between">
          <div className={`page-title-right ${btnClassName} ${customClassName}`}   >
            <ol className="breadcrumb m-0">
              <BreadcrumbItem>
                <Link to={titlePath ? titlePath : "#"}>{title}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {/* <Link to="#"> */}
                {breadcrumbItem}
                {/* </Link> */}
              </BreadcrumbItem>
            </ol>
          </div>
          <div className="d-sm-flex align-items-center wrap-flex">
            {isShowFilter && <div className={filter ? "active" : undefined} onClick={onFilterClick}>
              <FeatherIcon icon="filter" />
            </div>}
            {isShowCreate && <div onClick={onCreateClick}>
              <FeatherIcon icon="plus-circle" />
            </div>}
            {isShowFirstButton && <Button
              label={firstButtonLabel}
              className={`btn-primary ms-2 ${btnClassName}`}
              type="button"
              onClick={onFirstButtonClick}
              disabled={firstButtonDisabled}

            ></Button>}
            {isShowSecondButton && <Button
              label={secondButtonLabel}
              className={`btn-primary ms-2 ${btnClassName}`}
              type="button"
              onClick={onSecondButtonClick}
              disabled={secondButtonDisabled}
            ></Button>}
            {isShowThirdButton && <Button
              label={thirdButtonLabel}
              className={`btn-primary ms-2 ${btnClassName}`}
              type="button"
              onClick={onThirdButtonClick}
              disabled={thirdButtonDisabled}
            ></Button>}
            {isShowForthButton && <Button
              label={forthButtonLabel}
              className={`btn-primary ms-2 ${btnClassName}`}
              type="button"
              onClick={onForthButtonClick}
              disabled={forthButtonDisabled}
            ></Button>}
            {isShowFifthButton && <Button
              label={fifthButtonLabel}
              className={`btn-primary ms-2 ${btnClassName}`}
              type="button"
              onClick={onFifthButtonClick}
              disabled={fifthButtonDisabled}
            ></Button>}
            {isShowSithButton && <Button
              label={sixthButtonLabel}
              className={`btn-primary ms-2 ${btnClassName}`}
              type="button"
              onClick={onSixthButtonClick}
              disabled={sithButtonDisabled}
            ></Button>}
            {isShowSeventhButton && <Button
              label={seventhButtonLabel}
              className={`btn-primary ms-2 ${btnClassName}`}
              type="button"
              onClick={onSeventhButtonClick}
              disabled={seventhButtonDisabled}
            ></Button>}


            {isShowEighthButton && <Button
              label={eighthButtonLabel}
              className={`btn-primary ms-2 ${btnClassName}`}
              type="button"
              onClick={onEighthButtonClick}
              disabled={eighthButtonDisabled}
            ></Button>}

            {isShowNinethButton && <Button
              label={ninethButtonLabel}
              className={`btn-primary ms-2 ${btnClassName}`}
              type="button"
              onClick={onNinethButtonClick}
              disabled={ninethButtonDisabled}
            ></Button>}

            {isShownineteenthButton && <Button
              label={nineteenthButtonLabel}
              className={`btn-primary ms-2 ${btnClassName}`}
              type="button"
              onClick={onNineteenthButtonClick}
              disabled={nineteenthButtonDisabled}
            ></Button>}

            {isShowTenthButton && <Button
              label={tenthButtonLabel}
              className={`btn-primary ms-2 ${btnClassName}`}
              type="button"
              onClick={onTenthButtonClick}
              disabled={tenthButtonDisabled}
            ></Button>}

            {isShowEleventhButton && <Button
              label={eleventhButtonLabel}
              className={`btn-primary ms-2 ${btnClassName}`}
              type="button"
              onClick={onEleventhButtonClick}
              disabled={eleventhButtonDisabled}
            ></Button>}

            {isShowTwelvethButton && <Button
              label={twelvethButtonLabel}
              className={`btn-primary ms-2 ${btnClassName}`}
              type="button"
              onClick={onTwelvethButtonClick}
              disabled={twelvethButtonDisabled}
            ></Button>}

            {isShowThirteenthButton && <Button
              label={thirteenthButtonLabel}
              className={`btn-primary ms-2 ${btnClassName}`}
              type="button"
              onClick={onThirteenthButtonClick}
              disabled={thirteenthButtonDisabled}
            ></Button>}

            {isShowFourteenthButton && <Button
              label={fourteenthButtonLabel}
              className={`btn-primary ms-2 ${btnClassName}`}
              type="button"
              onClick={onFourteenthButtonClick}
              disabled={fourteenthButtonDisabled}
            ></Button>}

            {isShowFifteenthButton && <Button
              label={fifteenthButtonLabel}
              className={`btn-primary ms-2 ${btnClassName}`}
              type="button"
              onClick={onFifteenthButtonClick}
              disabled={fifteenthButtonDisabled}
            ></Button>}

            {
              isShowSixteenthButton && <Button
                label={sixteenthButtonLabel}
                className={`btn-primary ms-2 ${btnClassName}`}
                type="button"
                onClick={onSixteenthButtonClick}
                disabled={sixteenthButtonDisabled}
              ></Button>
            }

            {
              isShowSeventeenthButton && <Button
                label={seventeenthButtonLabel}
                className={`btn-primary ms-2 ${btnClassName}`}
                type="button"
                onClick={onSeventeenthButtonClick}
                disabled={seventeenthButtonDisabled}
              ></Button>
            }
            {
              isShowEighteenthButton && <Button
                label={eighteenthButtonLabel}
                className={`btn-primary ms-2 ${btnClassName}`}
                type="button"
                onClick={onEighteenthButtonClick}
                disabled={false}
              ></Button>
            }
            {
              isShowTwenteenthButton && <Button
                label={TwenteenthButtonLabel}
                className={`btn-primary ms-2 ${btnClassName}`}
                type="button"
                onClick={onTwenteenthButtonClick}
                disabled={false}
              ></Button>
            }
            {
              isShowTwentyOneButton && <Button
                label={TwentyOneButtonLabel}
                className={`btn-primary ms-2 ${btnClassName}`}
                type="button"
                onClick={onTwentyOneButtonClick}
                disabled={false}
              ></Button>
            }


          </div>
          <AnimatePresence> {selectedCustomers.length > 0 && <BulkActionStatus count={selectedCustomers.length} title={bulkActionSatusTitle}></BulkActionStatus>}</AnimatePresence>
        </div>
      </Col>
    </Row>
  );
};

export default BreadcrumbWithFilter;
