import {
  all,
  call,
  delay,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { API } from "src/util/api";
import { JobSheetTypes } from "./actionTypes";
import {
  getJobsheetByIdError,
  getJobsheetByIdSuccess,
  getJobsheetsListError,
  getJobsheetsListSuccess,
  deleteJobsheetsFromListError,
  deleteJobsheetsFromListSuccess,
  createJobsheetError,
  createJobsheetSuccess,
  jobDealerdropdownListSuccess,
  jobDealerdropdownListError,
  getVehiclebrandRelatedtoDealerSuccess,
  getVehiclebrandRelatedtoDealerError,
  getcustomerdropdownListError,
  getcustomerdropdownListSucces,
  getAudioModelJobdropdownListSuccess,
  getAudioModelJobdropdownListError,
  getPendingReasonError,
  getPendingReasonSuccess,
  audioModelDetailsForJobSuccess,
  audioModelDetailsForJobError,
  employeeDropdownForJobSuccess,
  employeeDropdownForJobError,
  courierDropdownForJobError,
  courierDropdownForJobSuccess,
  updatePendingCommandSuccess,
  updatePendingCommandError,
  updateServiceRequestJobSuccess,
  updateServiceRequestJobError,
  updateCreateJobSuccess,
  updateCreateJobError,
  getTechnicianDropdownSuccess,
  getTechnicianDropdownError,
  getEstimatorDropdownError,
  getEstimatorDropdownSuccess,
  getVehiclebrandDropdownSuccess,
  getVehiclebrandDropdownError,
  updateAssignToEstimateJobSuccess,
  updateAssignToEstimateJobError,
  partsDropdownForJobSuccess,
  partsDropdownForJobError,
  getPartDetailsForJobSuccess,
  getPartDetailsForJobError,
  updateEstimateCompleteJobSuccess,
  updateEstimateCompleteJobError,
  taxDropdownForJobSuccess,
  taxDropdownForJobError,
  taxDetailsForJobSuccess,
  taxDetailsForJobError,
  deletePendingCommandSuccess,
  deletePendingCommandError,
  updateEstimateApproveOrRejectJobSuccess,
  updateEstimateApproveOrRejectJobError,
  updateAssignToTechnicianJobSuccess,
  updateAssignToTechnicianJobError,
  updateReadyToInvoiceJobError,
  updateReadyToInvoiceJobSuccess,
  updateJobInvoicedSuccess,
  updateJobInvoicedError,
  updateJobClosedSuccess,
  updateJobClosedError,
  getInvoiceDetailsByJobIdSuccess,
  getInvoiceDetailsByJobIdError,
  getJobsheetHistoryByAudioSerialSuccess,
  getJobsheetHistoryByAudioSerialError,
  downloadServiceRequestSuccess,
  downloadServiceRequestError,
  downloadJobSheetSuccess,
  downloadJobSheetError,
  updateReplacementSystemDispatchError,
  updateReplacementSystemDispatchSuccess,
  updateZeroEstimateAssignToTechnicianJobSuccess,
  updateZeroEstimateAssignToTechnicianJobError,
  receivedPaymentForInvoiceJobSheetSuccess,
  receivedPaymentForInvoiceJobSheetError,
  downloadEstimateJobSheetSuccess,
  downloadEstimateJobSheetError,
  sendMailJobSheetSuccess,
  sendMailJobSheetError,
  sendSMSJobSheetSuccess,
  sendSMSJobSheetError,
  downloadInvoiceJobSheetSuccess,
  downloadInvoiceJobSheetError,
  sendInvoiceMailJobSheetError,
  sendInvoiceMailJobSheetSuccess,
  getEstimateConcernSignatureSuccess,
  getEstimateConcernSignatureError,
  getPreparebyIdSuccess,
  getPreparebyIdErrro,
  getPreparebyIdLoading,
  updatedByUserDetailsSuccess,
  updatedByUserDetailsError,
  updatedByUserDetailsLoading,
  getTaxesDropdownInJobsheetSuccess,
  getTaxesDropdownInJobsheetError,
  franchiseArrivedSuccess,
  franchiseArrivedError,
  franchiseCompletedJobError,
  franchiseCompletedJobSuccess,
  customerOrDelaerLatLagSuccess,
  customerOrDelaerLatLagError,
  additionalExpensedocumentuploadSuccess,
  additionalExpensedocumentuploadError,
  additionalExpenseGridSuccess,
  additionalExpenseGridError,
  additionalExpenseApproveError,
  additionalExpenseApproveSuccess,
  getAdditionalExpenseDetailsSuccess,
  getAdditionalExpenseDetailsError,
  getFranchiseUploadedDocumentSuccess,
  getFranchiseUploadedDocumentError,
  getFranchiseUploadedDocumentLoading,
  getFranchiseUploadedDocumentLinkError,
  getFranchiseUploadedDocumentLinkSuccess,
  getTechnicianbyIdSuccess,
  getTechnicianbyIdError,
  estimatorByUserDetailsSuccess,
  estimatorByUserDetailsError,
  getJobsheetsStatusSuccess,
  setJobsheetsStatusSuccess,
  additionalExpenseUpdateSuccess,
  additionalExpenseUpdateError,
  getJobsheetEnquiryByIdSuccess,
  getJobsheetEnquiryByIdError,
  printExchangeInvoiceSuccess,
  printExchangeInvoiceError,
  emailExchangeInvoiceSuccess,
  emailExchangeInvoiceError,
  deleteAdditionalExpensesDocumentSuccess,
  deleteAdditionalExpensesDocumentError,
  getAdditionalExpenseWithoutquerySuccess,
  getAdditionalExpenseWithoutqueryError,
  getApproveDocumentSuccess,
  getApproveDocumentError,
  getApproveDocumentLinkSuccess,
  getApproveDocumentLinkError,
  getAdditionalExpenseWithoutqueryLoading,
  franchiseUploadedDocumentDeleteSuccess,
  franchiseUploadedDocumentDeleteError,
  additionalExpensesDocumentLinkError,
  additionalExpensesDocumentLinkSuccess,
  getJobsheetFeedbackPendingListSuccess,
  getJobsheetFeedbackPendingListError,
  courierDropdownAllDataForJobError,
  courierDropdownAllDataForJobSuccess,
  updateCancelJobSuccess,
  updateCancelJobError,
  checkDealerHaveFeedbackPendingSuccess,
  checkDealerHaveFeedbackPendingError,
  getCustmerDetailsInJobsheetSuccess,
  getCustmerDetailsInJobsheetError,
  getCustmerProfileSuccess,
  getCustmerProfileError,
  getUserCurretnAddressLatLngSuccess,
  getUserCurretnAddressLatLngError,
  getJobsheetNumberSuccess,
  getJobsheetNumberError,
  getAudioModelSuccess,
  getAudioModelError,
  newAdditionalExpensesUpdateError,
  newAdditionalExpensesUpdateSuccess,
  getAdditionalExpenseUserDetailsSuccess,
  getAdditionalExpenseUserDetailsError,
  emailServiceHistorySuccess,
  emailServiceHistoryError,
  printServiceHistorySuccess,
  printServiceHistoryError,
  sendWhatsappEstimateSuccess,
  sendWhatsappEstimateError,
  sendInvoiceWhatsappJobSheetSuccess,
  sendInvoiceWhatsappJobSheetError,
  whatsappExchangeInvoiceSuccess,
  whatsappExchangeInvoiceError,
} from "./action";
import {
  ExpensesItem,
  TaxesDropdownFromJobSheetModel,
  jobsheet,
  prepareByUserModel,
  updatedByUserModel,
} from "src/models/pages/jobsheetModel";
import { AxiosResponse } from "axios";
import { cA } from "@fullcalendar/core/internal-common";
import {
  checkIncomingDispatchValidOrNot,
  checkOutgoingDispatchValidOrNot,
} from "src/helpers/common_helpers";

interface Apiresponse {
  data: ExpensesItem;
}

interface getadditionaldetails {
  data: {
    items: ExpensesItem[];
  };
}

interface CustomResponse {
  raw: {
    status: number;
    statusText: string;
    headers: any;
    config: any;
    data: any;
  };
  data: any;
}

//get JobSheet list
function* getJobSheetListAsync(action: any) {
  try {
    // const response: Promise<any> = yield call(API.getJobsheetListAPI, { ...action.lazyState })
    const response: Promise<any> = yield call(API.getJobPagesAPI, {
      ...action.lazyState,
    });
    yield put(getJobsheetsListSuccess(response));
  } catch (error) {
    yield put(getJobsheetsListError(error));
  }
}

//get JobSheet list
function* getJobSheetHistoryListAsync(action: any) {
  try {
    // const response: Promise<any> = yield call(API.getJobsheetListAPI, { ...action.lazyState })
    const response: Promise<any> = yield call(
      API.checkHistoricJobAPI,
      action.payload
    );
    yield put(getJobsheetHistoryByAudioSerialSuccess(response));
  } catch (error) {
    yield put(getJobsheetHistoryByAudioSerialError(error));
  }
}

//deleteJobSheetList
function* deleteJobSheetAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.deleteJobsheetListAPI, {
      id: action.payload.id,
      undo: action.payload.undo,
    });
    yield put(deleteJobsheetsFromListSuccess(response, action.payload.undo));
  } catch (error) {
    yield put(deleteJobsheetsFromListError(error));
  }
}

//JobSheet by id
function* fetchJobsheet(action: any): Generator<any, void, any> {
  try {
    debugger;
    let response = yield call(API.getJobByIdAPI, action.payload);
    yield put(getJobsheetByIdSuccess(response));
    delay(1000);
    const estimatedDetails = response?.data?.jobEstimateDto.filter(
      (eachValue: any) => !eachValue.isDeleted
    )?.[0];
    if (response?.data?.preparedById != null) {
      yield put(getPreparebyIdLoading(response?.data?.preparedById));
    }
    if (
      estimatedDetails?.updatedByGuid != null ||
      estimatedDetails?.updatedByGuid != undefined
    ) {
      yield put(updatedByUserDetailsLoading(estimatedDetails?.updatedByGuid));
    }
  } catch (error) {
    yield put(getJobsheetByIdError(error));
  }
}

//create JobSheet
function* createJobSheetAsync(action: any) {
  debugger;
  let payload = { ...action.payload.jobsheet };
  delete payload["pendingCommentsDto"];
  let isNextState = action.payload.jobsheet.IsAfcsGenerated;
  delete payload["IsAfcsGenerated"];
  let pendingCommandPayload: any =
    action.payload?.jobsheet.pendingCommentsDto?.map((eachPending: any) => {
      let data: any = {};
      data["id"] = eachPending.id;
      data["pendingReasonId"] = eachPending.pendingReasonId;
      data["pendingInformation"] = eachPending.pendingInformation;
      data["commentedAt"] = eachPending.commentedAt;
      data["jobId"] = action.payload.jobsheet.id;
      return data;
    });

  payload["pendingCommentsDto"] = pendingCommandPayload;
  try {
    if (action.payload.id) {
      payload["pendingComments"] = pendingCommandPayload;
      delete payload["pendingCommentsDto"]
      const { data } = yield call(API.updateJobsheetAPI, payload);
      if (data && isNextState) {
        const { data } = yield call(API.postJobsheetAPI, payload, isNextState);
        yield put(createJobsheetSuccess(data, action.payload.id));
      } else {
        yield put(createJobsheetSuccess(data, action.payload.id));
      }
    } else {
      const { data } = yield call(API.postJobsheetAPI, payload, isNextState);
      yield put(createJobsheetSuccess(data, action.payload.id));
    }
  } catch (error) {
    yield put(createJobsheetError(error));
  }
}

function* updateReplacementSystemDispatchStatus(action: any) {
  debugger;
  try {
    let payload: any = { ...action.payload };
    delete payload["pendingCommentsDto"];
    let incomingDispatch: any = payload?.incomingDispatch;
    // delete payload["incomingDispatch"];
    let isNextState = action.payload.IsAfcsGenerated;
    delete payload["IsAfcsGenerated"];
    let pendingCommandPayload: any = action.payload?.pendingCommentsDto?.map(
      (eachPending: any) => {
        let data: any = {};
        (data["jobId"] = action.payload.id), (data["id"] = eachPending.id);
        data["pendingReasonId"] = eachPending.pendingReasonId;
        data["pendingInformation"] = eachPending.pendingInformation;
        data["commentedAt"] = eachPending.commentedAt;
        return data;
      }
    );

    payload["pendingComments"] = [...pendingCommandPayload];

    let outgoingPayload = payload["outgoingDispatch"];
    if (!checkOutgoingDispatchValidOrNot(outgoingPayload)) {
      delete payload["outgoingDispatch"];
    } else {
      delete payload["outgoingDispatch"];
      payload["outgoingDispatch"] = outgoingPayload;
    }
    delete payload["expenseName"];
    delete payload["taxCode"];
    delete payload["taxPercentage"];
    delete payload["amountWithTax"];

    const { data } = yield call(API.updateJobsheetAPI, payload);
    if (data && isNextState) {
      outgoingPayload["jobId"] = action.payload.id;
      const { data } = yield call(
        API.updateOutgoingDispatchForJobAPI,
        outgoingPayload,
        payload["id"]
      );
      if (data && isNextState) {
        delete payload["pendingComments"];
        let replacementSystemDispatchedPayload = {
          id: action.payload.id,
        };
        const { data } = yield call(
          API.updateReplacementSystemDispatchAPI,
          replacementSystemDispatchedPayload
        );
        yield put(updateReplacementSystemDispatchSuccess(data));
      } else {
        yield put(updateReplacementSystemDispatchSuccess(data));
      }
    } else {
      yield put(updateReplacementSystemDispatchSuccess(data));
    }
    // updateIncomingDispatchForJobAPI
  } catch (error) {
    yield put(updateReplacementSystemDispatchError(error));
  }
}

function* updateCreateJobStatus(action: any) {
  debugger;
  try {
    let payload: any = { ...action.payload };

    delete payload["pendingCommentsDto"];
    let incomingDispatch: any = payload?.incomingDispatch;
    // if(!checkIncomingDispatchValidOrNot(incomingDispatch)){
    //   delete payload['incomingDispatch']
    // }
    let isNextState = action.payload.IsAfcsGenerated;
    delete payload["IsAfcsGenerated"];
    let pendingCommandPayload: any = action.payload?.pendingCommentsDto?.map(
      (eachPending: any) => {
        let data: any = {};
        (data["jobId"] = action.payload.id), (data["id"] = eachPending.id);
        data["pendingReasonId"] = eachPending.pendingReasonId;
        data["pendingInformation"] = eachPending.pendingInformation;
        data["commentedAt"] = eachPending.commentedAt;
        return data;
      }
    );

    payload["pendingComments"] = [...pendingCommandPayload];

    delete payload["expenseName"];
    delete payload["taxCode"];
    delete payload["taxPercentage"];
    delete payload["amountWithTax"];

    debugger;
    const { data } = yield call(API.updateJobsheetAPI, payload);

    // const { data } = yield call(API.updateIncomingDispatchForJobAPI, incomingDispatch, payload['id']);
    if (data && isNextState) {
      delete payload["pendingComments"];
      const { data } = yield call(API.updateCreateJobStatusAPI, payload);
      yield put(updateCreateJobSuccess(data));
    } else {
      yield put(updateCreateJobSuccess(data));
    }
    // updateIncomingDispatchForJobAPI
  } catch (error) {
    yield put(updateCreateJobError(error));
  }
}

function* updateAssignToEstimateJobStatus(action: any) {
  try {
    let payload: any = { ...action.payload };
    delete payload["pendingCommentsDto"];
    let incomingDispatch: any = payload?.incomingDispatch;
    // delete payload["incomingDispatch"];
    let isNextState = action.payload.IsAfcsGenerated;
    delete payload["IsAfcsGenerated"];
    let pendingCommandPayload: any = action.payload?.pendingCommentsDto?.map(
      (eachPending: any) => {
        let data: any = {};
        (data["jobId"] = action.payload.id), (data["id"] = eachPending.id);
        data["pendingReasonId"] = eachPending.pendingReasonId;
        data["pendingInformation"] = eachPending.pendingInformation;
        data["commentedAt"] = eachPending.commentedAt;
        return data;
      }
    );

    payload["pendingComments"] = [...pendingCommandPayload];
    delete payload["expenseName"];
    delete payload["taxCode"];
    delete payload["taxPercentage"];
    delete payload["amountWithTax"];

    const { data } = yield call(API.updateJobsheetAPI, payload);

    if (data && isNextState) {
      let modifiedValue = {
        id: action.payload.id,
        estimatorId: payload.estimatorId,
        estimatorGUId: payload.estimatorGUId,
      };
      const { data } = yield call(
        API.updateAssignToEstimateJobStatusAPI,
        modifiedValue
      );
      yield put(updateAssignToEstimateJobSuccess(data));
    } else {
      yield put(updateAssignToEstimateJobSuccess(data));
    }
    // updateIncomingDispatchForJobAPI
  } catch (error) {
    yield put(updateAssignToEstimateJobError(error));
  }
}

function* updateEstimateCompleteJobStatus(action: any) {
  try {
    let payload: any = { ...action.payload };
    delete payload["pendingCommentsDto"];
    let incomingDispatch: any = payload?.incomingDispatch;
    // delete payload["incomingDispatch"];
    let isNextState = action.payload.IsAfcsGenerated;
    delete payload["IsAfcsGenerated"];
    let pendingCommandPayload: any = action.payload?.pendingCommentsDto?.map(
      (eachPending: any) => {
        let data: any = {};
        (data["jobId"] = action.payload.id), (data["id"] = eachPending.id);
        data["pendingReasonId"] = eachPending.pendingReasonId;
        data["pendingInformation"] = eachPending.pendingInformation;
        data["commentedAt"] = eachPending.commentedAt;
        return data;
      }
    );

    payload["pendingComments"] = [...pendingCommandPayload];

    let estimatePayload = payload["estimate"];
    delete payload["estimate"];
    delete payload["expenseName"];
    delete payload["taxCode"];
    delete payload["taxPercentage"];
    delete payload["amountWithTax"];

    const { data } = yield call(API.updateJobsheetAPI, payload);
    if (data) {
      const { data } = yield call(
        API.updateEstimateCompeteJobStatusAPI,
        estimatePayload
      );
      yield put(updateEstimateCompleteJobSuccess(data));
    }

    // updateIncomingDispatchForJobAPI
  } catch (error) {
    yield put(updateEstimateCompleteJobError(error));
  }
}

function* updateEstimateApprovalJobStatus(action: any) {
  debugger;
  console.log("action", action);
  try {
    let payload: any = { ...action.payload };
    delete payload["pendingCommentsDto"];
    let incomingDispatch: any = payload?.incomingDispatch;
    // delete payload["incomingDispatch"];
    let isNextState = action.payload.IsAfcsGenerated;
    delete payload["IsAfcsGenerated"];
    let pendingCommandPayload: any = action.payload?.pendingCommentsDto?.map(
      (eachPending: any) => {
        let data: any = {};
        (data["jobId"] = action.payload.id), (data["id"] = eachPending.id);
        data["pendingReasonId"] = eachPending.pendingReasonId;
        data["pendingInformation"] = eachPending.pendingInformation;
        data["commentedAt"] = eachPending.commentedAt;
        return data;
      }
    );

    payload["pendingComments"] = [...pendingCommandPayload];
    let estimatePayload = payload["estimate"];
    delete payload["estimate"];
    delete payload["expenseName"];
    delete payload["taxCode"];
    delete payload["taxPercentage"];
    delete payload["amountWithTax"];

    if (action.payload.approvedoc?.length > 0) {
      const response: CustomResponse = yield call(
        API.approvedocumentApi,
        action.payload.id,
        action.payload.approvedoc
      );
      const statusCode = response?.raw.status;
      if (statusCode == 204) {
        let estimateUploadOption = {
          jobId: action.payload.id,
          estimatorComments: estimatePayload["estimatorComments"],
          totalEstimation: estimatePayload["totalEstimation"],
          isCompleted: true,
          taxTypeId: estimatePayload["taxTypeId"],
          estimatePartDto: estimatePayload["estimatePartDto"],
          estimateTaxDto: estimatePayload["estimateTaxDto"],
        };

        if (isNextState == false) {
          const { data } = yield call(
            API.normalUpdateEstimate,
            estimateUploadOption
          );
          if (data) {
            const { data } = yield call(API.updateJobsheetAPI, payload);
            if (data) {
              yield put(updateEstimateApproveOrRejectJobSuccess(data));
            }
          }
        } else {
          const { data } = yield call(API.updateJobsheetAPI, payload);
          if (data) {
            let approvePayload = {
              jobId: action.payload.id,
              approvalMode: estimatePayload["approvalMode"],
              approvalComments: estimatePayload["approvalComments"],
              approvedStatus: estimatePayload["approvedStatus"],
              concernSignature: estimatePayload["concernSignature"],
              contentType: estimatePayload["contentType"],
              estimatePartDto: estimatePayload["estimatePartDto"],
              estimateTaxDto: estimatePayload["estimateTaxDto"],
              estimatorComments: estimatePayload["estimatorComments"],
              taxTypeId: estimatePayload["taxTypeId"],
            };
            delete estimatePayload["approvalMode"];
            delete estimatePayload["approvalComments"];
            delete estimatePayload["concernSignature"];
            delete estimatePayload["contentType"];
            delete estimatePayload["estimatePartDto"];
            delete estimatePayload["estimateTaxDto"];
            delete estimatePayload["estimatorComments"];
            delete estimatePayload["taxTypeId"];
            estimatePayload["isCompleted"] = true;
            if (data) {
              if (estimatePayload["approvedStatus"] == true) {
                const { data } = yield call(
                  API.updateEstimateApprovalJobStatusAPI,
                  approvePayload
                );
                if (!approvePayload.approvedStatus && data) {
                  const { data } = yield call(
                    API.rejectEstimationAPI,
                    approvePayload
                  );
                  yield put(updateEstimateApproveOrRejectJobSuccess(data));
                } else {
                  yield put(updateEstimateApproveOrRejectJobSuccess(data));
                }
              } else {
                if (!approvePayload.approvedStatus && data) {
                  const { data } = yield call(
                    API.rejectEstimationAPI,
                    approvePayload
                  );
                  yield put(updateEstimateApproveOrRejectJobSuccess(data));
                } else {
                  yield put(updateEstimateApproveOrRejectJobSuccess(data));
                }
              }
            }
          }
        }

        // const { data } = yield call(API.updateJobsheetAPI, payload);
        // if (data) {
        //   let approvePayload = {
        //     "jobId": action.payload.id,
        //     "approvalMode": estimatePayload['approvalMode'],
        //     "approvalComments": estimatePayload['approvalComments'],
        //     "approvedStatus": estimatePayload['approvedStatus'],
        //     "concernSignature": estimatePayload['concernSignature'],
        //     "contentType": estimatePayload['contentType'],
        //     "estimatePartDto": estimatePayload['estimatePartDto'],
        //     "estimateTaxDto": estimatePayload['estimateTaxDto'],
        //     "estimatorComments": estimatePayload['estimatorComments'],
        //     "taxTypeId": estimatePayload['taxTypeId'],
        //   };
        //   delete estimatePayload['approvalMode'];
        //   delete estimatePayload['approvalComments'];
        //   delete estimatePayload['concernSignature'];
        //   delete estimatePayload['contentType'];
        //   delete estimatePayload['estimatePartDto'];
        //   delete estimatePayload['estimateTaxDto'];
        //   delete estimatePayload['estimatorComments'];
        //   delete estimatePayload['taxTypeId'];
        //   estimatePayload['isCompleted'] = true;
        //   if (data && isNextState) {
        //     if (estimatePayload['approvedStatus'] == true) {
        //       const { data } = yield call(API.updateEstimateApprovalJobStatusAPI, approvePayload);
        //       if (!approvePayload.approvedStatus && data) {
        //         const { data } = yield call(API.rejectEstimationAPI, approvePayload);
        //         yield put(updateEstimateApproveOrRejectJobSuccess(data));
        //       } else {
        //         yield put(updateEstimateApproveOrRejectJobSuccess(data));
        //       }
        //     } else {
        //       if (!approvePayload.approvedStatus && data) {
        //         const { data } = yield call(API.rejectEstimationAPI, approvePayload);
        //         yield put(updateEstimateApproveOrRejectJobSuccess(data));
        //       } else {
        //         yield put(updateEstimateApproveOrRejectJobSuccess(data));
        //       }
        //     }
        //   } else {
        //     yield put(updateEstimateApproveOrRejectJobSuccess(data));
        //   }

        // }
      }
    } else {
      let estimateUploadOption = {
        jobId: action.payload.id,
        estimatorComments: estimatePayload["estimatorComments"],
        totalEstimation: estimatePayload["totalEstimation"],
        isCompleted: true,
        taxTypeId: estimatePayload["taxTypeId"],
        estimatePartDto: estimatePayload["estimatePartDto"],
        estimateTaxDto: estimatePayload["estimateTaxDto"],
      };
      if (isNextState == false) {
        const { data } = yield call(
          API.normalUpdateEstimate,
          estimateUploadOption
        );
        if (data) {
          const { data } = yield call(API.updateJobsheetAPI, payload);
          if (data) {
            yield put(updateEstimateApproveOrRejectJobSuccess(data));
          }
        }
      } else {
        const { data } = yield call(API.updateJobsheetAPI, payload);
        if (data && isNextState) {
          let approvePayload = {
            jobId: action.payload.id,
            approvalMode: estimatePayload["approvalMode"],
            approvalComments: estimatePayload["approvalComments"],
            approvedStatus: estimatePayload["approvedStatus"],
            concernSignature: estimatePayload["concernSignature"],
            contentType: estimatePayload["contentType"],
            estimatePartDto: estimatePayload["estimatePartDto"], // new added
            estimateTaxDto: estimatePayload["estimateTaxDto"], // new added
            estimatorComments: estimatePayload["estimatorComments"], // new added
            taxTypeId: estimatePayload["taxTypeId"], // new added
          };
          delete estimatePayload["approvalMode"];
          delete estimatePayload["approvalComments"];
          delete estimatePayload["concernSignature"];
          delete estimatePayload["contentType"];
          delete estimatePayload["estimatePartDto"];
          delete estimatePayload["estimateTaxDto"];
          delete estimatePayload["estimatorComments"];
          delete estimatePayload["taxTypeId"];
          estimatePayload["isCompleted"] = true;

          if (data) {
            if (estimatePayload["approvedStatus"] == true) {
              const { data } = yield call(
                API.updateEstimateApprovalJobStatusAPI,
                approvePayload
              );
              if (!approvePayload.approvedStatus && data) {
                const { data } = yield call(
                  API.rejectEstimationAPI,
                  approvePayload
                );
                yield put(updateEstimateApproveOrRejectJobSuccess(data));
              } else {
                yield put(updateEstimateApproveOrRejectJobSuccess(data));
              }
            } else {
              if (!approvePayload.approvedStatus && data) {
                const { data } = yield call(
                  API.rejectEstimationAPI,
                  approvePayload
                );
                yield put(updateEstimateApproveOrRejectJobSuccess(data));
              } else {
                yield put(updateEstimateApproveOrRejectJobSuccess(data));
              }
            }
          } else {
            yield put(updateEstimateApproveOrRejectJobSuccess(data));
          }
        }
      }

      // if (data && isNextState) {
      //   let approvePayload = {
      //     jobId: action.payload.id,
      //     approvalMode: estimatePayload["approvalMode"],
      //     approvalComments: estimatePayload["approvalComments"],
      //     approvedStatus: estimatePayload["approvedStatus"],
      //     concernSignature: estimatePayload["concernSignature"],
      //     contentType: estimatePayload["contentType"],
      //     estimatePartDto: estimatePayload["estimatePartDto"], // new added
      //     estimateTaxDto: estimatePayload["estimateTaxDto"], // new added
      //     estimatorComments: estimatePayload["estimatorComments"], // new added
      //     taxTypeId: estimatePayload["taxTypeId"], // new added
      //   };
      //   delete estimatePayload["approvalMode"];
      //   delete estimatePayload["approvalComments"];
      //   delete estimatePayload["concernSignature"];
      //   delete estimatePayload["contentType"];
      //   delete estimatePayload["estimatePartDto"];
      //   delete estimatePayload["estimateTaxDto"];
      //   delete estimatePayload["estimatorComments"];
      //   delete estimatePayload["taxTypeId"];
      //   estimatePayload["isCompleted"] = true;

      //   if (data) {
      //     if (estimatePayload["approvedStatus"] == true) {
      //       const { data } = yield call(
      //         API.updateEstimateApprovalJobStatusAPI,
      //         approvePayload
      //       );
      //       if (!approvePayload.approvedStatus && data) {
      //         const { data } = yield call(
      //           API.rejectEstimationAPI,
      //           approvePayload
      //         );
      //         yield put(updateEstimateApproveOrRejectJobSuccess(data));
      //       } else {
      //         yield put(updateEstimateApproveOrRejectJobSuccess(data));
      //       }
      //     } else {
      //       if (!approvePayload.approvedStatus && data) {
      //         const { data } = yield call(
      //           API.rejectEstimationAPI,
      //           approvePayload
      //         );
      //         yield put(updateEstimateApproveOrRejectJobSuccess(data));
      //       } else {
      //         yield put(updateEstimateApproveOrRejectJobSuccess(data));
      //       }
      //     }
      //   } else {
      //     yield put(updateEstimateApproveOrRejectJobSuccess(data));
      //   }
      // } else {
      //   yield put(updateEstimateApproveOrRejectJobSuccess(data));
      // }
    }
  } catch (error) {
    yield put(updateEstimateApproveOrRejectJobError(error));
  }
}
function* updateCancelJobStatus(action: any) {
  try {
    let payload: any = { ...action.payload };
    const { data } = yield call(
      API.updateJobCancelStatusAPI,
      action.payload.id
    );
    yield put(updateCancelJobSuccess(data));
    // updateIncomingDispatchForJobAPI
  } catch (error) {
    yield put(updateCancelJobError(error));
  }
}

function* updateAssignToTechnicianJobStatus(action: any) {
  debugger;
  try {
    let payload: any = { ...action.payload };
    delete payload["pendingCommentsDto"];
    let incomingDispatch: any = payload?.incomingDispatch;
    // delete payload["incomingDispatch"];
    let isNextState = action.payload.IsAfcsGenerated;
    delete payload["IsAfcsGenerated"];
    let pendingCommandPayload: any = action.payload?.pendingCommentsDto?.map(
      (eachPending: any) => {
        let data: any = {};
        (data["jobId"] = action.payload.id), (data["id"] = eachPending.id);
        data["pendingReasonId"] = eachPending.pendingReasonId;
        data["pendingInformation"] = eachPending.pendingInformation;
        data["commentedAt"] = eachPending.commentedAt;
        return data;
      }
    );

    payload["pendingComments"] = [...pendingCommandPayload];

    let estimatePayload = payload["estimate"];
    delete payload["estimate"];
    let technicianId = payload["technicianId"];
    // delete payload['technicianId']
    // delete payload['expenseName']
    // delete payload['taxCode']
    // delete payload['taxPercentage']
    // delete payload['amountWithTax']

    const { data } = yield call(API.updateJobsheetAPI, payload);

    if (data && isNextState) {
      let techinicianPayload = {
        id: action.payload.id,
        technicianId: technicianId,
        technicianGUId: payload["technicianGUId"],
        appointmentTime: action.payload.appointmentTime,
        franchiseNotes: action.payload.franchiseNotes,
        serviceLocation: action.payload.serviceLocation,
      };
      const { data } = yield call(
        API.updateAssignToTechnicianStatusAPI,
        techinicianPayload
      );
      yield put(updateAssignToTechnicianJobSuccess(data));
    } else {
      yield put(updateAssignToTechnicianJobSuccess(data));
    }
    // updateIncomingDispatchForJobAPI
  } catch (error) {
    yield put(updateAssignToTechnicianJobError(error));
  }
}

function* updateZeroEstimateAssignToTechnicianJobStatus(action: any) {
  debugger;
  try {
    let payload: any = { ...action.payload };
    delete payload["pendingCommentsDto"];
    let incomingDispatch: any = payload?.incomingDispatch;
    // delete payload["incomingDispatch"];
    let isNextState = action.payload.IsAfcsGenerated;
    delete payload["IsAfcsGenerated"];
    let pendingCommandPayload: any = action.payload?.pendingCommentsDto?.map(
      (eachPending: any) => {
        let data: any = {};
        (data["jobId"] = action.payload.id), (data["id"] = eachPending.id);
        data["pendingReasonId"] = eachPending.pendingReasonId;
        data["pendingInformation"] = eachPending.pendingInformation;
        data["commentedAt"] = eachPending.commentedAt;
        return data;
      }
    );

    payload["pendingComments"] = [...pendingCommandPayload];

    let estimatePayload = payload["estimate"];
    delete payload["estimate"];
    delete payload["expenseName"];
    delete payload["taxCode"];
    delete payload["taxPercentage"];
    delete payload["amountWithTax"];

    const { data } = yield call(API.updateJobsheetAPI, payload);
    if (data) {
      let zeroEstimatePayload = {
        id: action.payload.id,
      };

      if (data && isNextState) {
        const { data } = yield call(
          API.updateZeroEstimateAssignToTechnicianAPI,
          zeroEstimatePayload
        );
        yield put(updateZeroEstimateAssignToTechnicianJobSuccess(data));
      } else {
        yield put(updateZeroEstimateAssignToTechnicianJobSuccess(data));
      }
    }

    // updateIncomingDispatchForJobAPI
  } catch (error) {
    yield put(updateZeroEstimateAssignToTechnicianJobError(error));
  }
}

function* updateReadyToInvoiceStatus(action: any) {
  debugger;
  try {
    let payload: any = { ...action.payload };
    delete payload["pendingCommentsDto"];
    let incomingDispatch: any = payload?.incomingDispatch;
    // delete payload["incomingDispatch"];
    let isNextState = action.payload.IsAfcsGenerated;
    delete payload["IsAfcsGenerated"];
    let pendingCommandPayload: any = action.payload?.pendingCommentsDto?.map(
      (eachPending: any) => {
        let data: any = {};
        (data["jobId"] = action.payload.id), (data["id"] = eachPending.id);
        data["pendingReasonId"] = eachPending.pendingReasonId;
        data["pendingInformation"] = eachPending.pendingInformation;
        data["commentedAt"] = eachPending.commentedAt;
        return data;
      }
    );
    payload["pendingComments"] = [...pendingCommandPayload];
    delete payload["estimate"];
    // delete payload['technicianId']
    payload["melObservations"] = payload["readyToInvoice"]?.melObservations;
    payload["completeServiceInformation"] =
      payload["readyToInvoice"].completeServiceInformation;
    payload["packingComplete"] = payload["readyToInvoice"].packingComplete;
    payload["cleaningComplete"] = payload["readyToInvoice"].cleaningComplete;
    payload["packagedAt"] = payload["readyToInvoice"].packagedAt;
    let readyToInvoicePayload = payload["readyToInvoice"];
    delete payload["readyToInvoice"];
    readyToInvoicePayload["id"] = action.payload.id;
    delete payload["expenseName"];
    delete payload["taxCode"];
    delete payload["taxPercentage"];
    delete payload["amountWithTax"];

    const { data } = yield call(API.updateJobsheetAPI, payload);
    if (data && isNextState) {
      const { data } = yield call(
        API.updateReadyToInvoiceStatusAPI,
        readyToInvoicePayload
      );
      yield put(updateReadyToInvoiceJobSuccess(data));
    } else {
      yield put(updateReadyToInvoiceJobSuccess(data));
    }
    // updateIncomingDispatchForJobAPI
  } catch (error) {
    yield put(updateReadyToInvoiceJobError(error));
  }
}

function* updateJobInvoicedStatus(action: any) {
  debugger;
  try {
    let payload: any = { ...action.payload };
    delete payload["pendingCommentsDto"];
    let incomingDispatch: any = payload?.incomingDispatch;
    // delete payload["incomingDispatch"];
    let isNextState = action.payload.IsAfcsGenerated;
    delete payload["IsAfcsGenerated"];
    let pendingCommandPayload: any = action.payload?.pendingCommentsDto?.map(
      (eachPending: any) => {
        let data: any = {};
        (data["jobId"] = action.payload.id), (data["id"] = eachPending.id);
        data["pendingReasonId"] = eachPending.pendingReasonId;
        data["pendingInformation"] = eachPending.pendingInformation;
        data["commentedAt"] = eachPending.commentedAt;
        return data;
      }
    );
    payload["pendingComments"] = [...pendingCommandPayload];
    payload["melObservations"] = payload["readyToInvoice"]?.melObservations;
    payload["completeServiceInformation"] =
      payload["readyToInvoice"].completeServiceInformation;
    payload["packingComplete"] = payload["readyToInvoice"].packingComplete;
    payload["cleaningComplete"] = payload["readyToInvoice"].cleaningComplete;
    payload["packagedAt"] = payload["readyToInvoice"].packagedAt;
    delete payload["estimate"];
    // delete payload['technicianId']
    let readyToInvoicePayload = payload["readyToInvoice"];
    delete payload["readyToInvoice"];
    readyToInvoicePayload["id"] = action.payload.id;

    let outgoingPayload = payload["outgoingDispatch"];
    delete payload["outgoingDispatch"];
    delete payload["expenseName"];
    delete payload["taxCode"];
    delete payload["taxPercentage"];
    delete payload["amountWithTax"];

    const { data } = yield call(API.updateJobsheetAPI, payload);
    if (data) {
      if (!payload?.isExchange && isNextState) {
        outgoingPayload["jobId"] = action.payload.id;
        // const { data } = yield call(API.updateOutgoingDispatchForJobAPI, outgoingPayload, action.payload.id);
        // const { data } = yield call(API.updateReadyToInvoiceStatusAPI, readyToInvoicePayload);
        if (data && isNextState) {
          const { data } = yield call(
            API.updateJobInvoicedStatusAPI,
            action.payload.id
          );
          yield put(updateJobInvoicedSuccess(data));
        } else {
          yield put(updateJobInvoicedSuccess(data));
        }
      } else {
        if (isNextState) {
          const { data } = yield call(
            API.updateJobInvoicedStatusAPI,
            action.payload.id
          );
          yield put(updateJobInvoicedSuccess(data));
        } else {
          yield put(updateJobInvoicedSuccess(data));
        }
      }
    } else {
      yield put(updateJobInvoicedSuccess(data));
    }
    // updateIncomingDispatchForJobAPI
  } catch (error) {
    yield put(updateJobInvoicedError(error));
  }
}

function* updateJobClosedStatus(action: any) {
  debugger;
  try {
    let payload: any = { ...action.payload };
    delete payload["pendingCommentsDto"];
    let incomingDispatch: any = payload?.incomingDispatch;
    // delete payload['incomingDispatch'] 24-06-2024
    let isNextState = action.payload.IsAfcsGenerated;
    delete payload["IsAfcsGenerated"];
    let pendingCommandPayload: any = action.payload?.pendingCommentsDto?.map(
      (eachPending: any) => {
        let data: any = {};
        (data["jobId"] = action.payload.id), (data["id"] = eachPending.id);
        data["pendingReasonId"] = eachPending.pendingReasonId;
        data["pendingInformation"] = eachPending.pendingInformation;
        data["commentedAt"] = eachPending.commentedAt;
        return data;
      }
    );
    payload["pendingComments"] = [...pendingCommandPayload];
    delete payload["estimate"];
    // delete payload['technicianId']
    let readyToInvoicePayload = payload["readyToInvoice"];
    delete payload["readyToInvoice"];
    readyToInvoicePayload["id"] = action.payload.id;
    delete payload["expenseName"];
    delete payload["taxCode"];
    delete payload["taxPercentage"];
    delete payload["amountWithTax"];

    let outgoingPayload = payload["outgoingDispatch"];
    delete payload["outgoingDispatch"];
    if (!checkOutgoingDispatchValidOrNot(outgoingPayload)) {
      delete payload["outgoingDispatch"];
    } else {
      payload["outgoingDispatch"] = outgoingPayload;
    }

    const { data } = yield call(API.updateJobsheetAPI, payload);
    if (data && isNextState) {
      if (!payload?.isExchange) {
        if (action.payload.isFranchise == true) {
          if (data) {
            const { data } = yield call(
              API.updateJobClosedStatusAPI,
              action.payload.id
            );
            yield put(updateJobClosedSuccess(data));
          }
        } else {
          if (!checkOutgoingDispatchValidOrNot(outgoingPayload)) {
            delete payload["outgoingDispatch"];
           
              const { data } = yield call(
                API.updateJobClosedStatusAPI,
                action.payload.id
              );
              yield put(updateJobClosedSuccess(data));
            
          } else {
            payload["outgoingDispatch"] = outgoingPayload;
          
          debugger
          outgoingPayload["jobId"] = action.payload.id;
          const { data } = yield call(
            API.updateOutgoingDispatchForJobAPI,
            outgoingPayload,
            action.payload.id
          );
          if (data) {
            const { data } = yield call(
              API.updateJobClosedStatusAPI,
              action.payload.id
            );
            yield put(updateJobClosedSuccess(data));
          }
        }
        }
      } else {
        const { data } = yield call(
          API.updateJobClosedStatusAPI,
          action.payload.id
        );
        yield put(updateJobClosedSuccess(data));
      }
    } else {
      yield put(updateJobClosedSuccess(data));
    }
  } catch (error) {
    yield put(updateJobClosedError(error));
  }
}

//dealer dropdown list

function* getDealerListAsync() {
  try {
    const response: Promise<any> = yield call(API.getDealerDrpFromIdentity);
    yield put(jobDealerdropdownListSuccess(response));
  } catch (error) {
    yield put(jobDealerdropdownListError(error));
  }
}

//get vehiclebrand related to dealer

function* getDealerVehiclebrandListAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.getDealerWithVehicleBrandForJobAPI,
      action.payload
    );
    yield put(getVehiclebrandRelatedtoDealerSuccess(response, action.payload));
  } catch (error) {
    yield put(getVehiclebrandRelatedtoDealerError(error));
  }
}

//get customer dropdown list

function* getcustomerDropdownListAsync() {
  try {
    // const response: Promise<any> = yield call(API.getCustomerDropForJobAPI);
    const response: Promise<any> = yield call(API.getCustomerDrpFromIdentity);
    yield put(getcustomerdropdownListSucces(response));
  } catch (error) {
    yield put(getcustomerdropdownListError(error));
  }
}

// get Audiomodel dropdwon list api

function* getAudioModelDropdwonListAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.getAudioModelDropdownForJobAPI,
      action.payload
    );
    yield put(getAudioModelJobdropdownListSuccess(response));
  } catch (error) {
    yield put(getAudioModelJobdropdownListError(error));
  }
}

//get pending reason

function* getPendingReasonLoading() {
  try {
    const response: Promise<any> = yield call(API.getpendingReasonDropdownList);
    yield put(getPendingReasonSuccess(response));
  } catch (error) {
    yield put(getPendingReasonError(error));
  }
}

//get vehiclebrand related to dealer

function* getAudioModelDetailsAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.getAudioModelTotalAmountWithTaxForJobAPI,
      action.payload,
      action.isExchange
    );
    yield put(audioModelDetailsForJobSuccess(response));
  } catch (error) {
    yield put(audioModelDetailsForJobError(error));
  }
}

function* getEmployeeDropdownForJobAsync() {
  try {
    const response: Promise<any> = yield call(API.getEmployeeDrpFromIdentity);
    yield put(employeeDropdownForJobSuccess(response));
  } catch (error) {
    yield put(employeeDropdownForJobError(error));
  }
}

function* getCourierDropdownForJobAsync(action: any) {
  try {
    // const response: Promise<any> = yield call(API.getCourierDropdownForJobAPI);
    const response: Promise<any> = yield call(
      API.getCourierDropdownForJobNewApi,
      action.payload
    );
    yield put(courierDropdownForJobSuccess(response));
  } catch (error) {
    yield put(courierDropdownForJobError(error));
  }
}

function* getCourierDropdownForAllDataJobAsync() {
  try {
    const response: Promise<any> = yield call(
      API.getCourierDropdownForAllJobNewApi
    );
    yield put(courierDropdownAllDataForJobSuccess(response));
  } catch (error) {
    yield put(courierDropdownAllDataForJobError(error));
  }
}

function* getPartsDropdownForJobAsync() {
  try {
    const response: Promise<any> = yield call(API.getPartsDropdownForJobAPI);
    yield put(partsDropdownForJobSuccess(response));
  } catch (error) {
    yield put(partsDropdownForJobError(error));
  }
}

function* getPartsDetailsForJobAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.getPartsDeatilsForJobAPI,
      action.payload.id
    );
    yield put(getPartDetailsForJobSuccess(response, action.payload.index));
  } catch (error) {
    yield put(getPartDetailsForJobError(error));
  }
}

function* getTaxListForJobAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.getTaxByIdForJobAPI,
      action.payload.id
    );
    yield put(taxDropdownForJobSuccess(response));
  } catch (error) {
    yield put(taxDropdownForJobError(error));
  }
}
function* getTaxDetailsForJobAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.getTaxByIdForJobAPI,
      action.payload.id
    );
    yield put(taxDetailsForJobSuccess(response, action.payload.index));
  } catch (error) {
    yield put(taxDetailsForJobError(error));
  }
}

//update pending command
function* updatePendingCommandJob(action: any) {
  try {
    const { data } = yield call(
      API.updatePendingCommentForJobAPI,
      action.payload
    );
    yield put(updatePendingCommandSuccess(data));
  } catch (error) {
    yield put(updatePendingCommandError(error));
  }
}

//get technician dropdown
function* getTechnicianDropdownAsync() {
  try {
    // const response: Promise<any> = yield call(API.getFranchiseDrpFromIdentity);
    const response: Promise<any> = yield call(
      API.getFranchiseDrpFromIdentityWithLocation
    );
    yield put(getTechnicianDropdownSuccess(response));
  } catch (error) {
    yield put(getTechnicianDropdownError(error));
  }
}

//get estimator dropdown
function* getEstimatorDropdownAsync(): any {
  try {
    const [response1, response2] = yield all([
      call(API.getEstimtoroneDropdownApi),
      call(API.getEstimtorTwoDropdownApi),
    ]);
    const data1 = response1.data;
    const data2 = response2.data;
    const data = [...data1, ...data2];
    yield put(getEstimatorDropdownSuccess(data));
  } catch (error) {
    yield put(getEstimatorDropdownError(error));
  }
}

//get vehiclebrand dropdown
function* getVehiclebrandDropdownAsync() {
  try {
    const response: Promise<any> = yield call(API.getVehiclebrandDropdownApi);
    yield put(getVehiclebrandDropdownSuccess(response));
  } catch (error) {
    yield put(getVehiclebrandDropdownError(error));
  }
}

// delete pending commands

function* deletePendingCommandsAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.deletePendingCommentForJobAPI,
      action.payload
    );
    yield put(deletePendingCommandSuccess(response));
  } catch (error) {
    yield put(deletePendingCommandError(error));
  }
}

// get invoice details

function* getInvoiceByJobIdAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.getInvoiceByJobIdApi,
      action.payload
    );
    yield put(getInvoiceDetailsByJobIdSuccess(response));
  } catch (error) {
    yield put(getInvoiceDetailsByJobIdError(error));
  }
}

//download Service request
function* printServiceRequestForJobAsync(action: any) {
  try {
    // const response: Promise<any> = yield call(API.getJobsheetListAPI, { ...action.lazyState })
    const response: Promise<any> = yield call(
      API.printServiceRequestAPI,
      action.payload
    );
    yield put(downloadServiceRequestSuccess(response));
  } catch (error) {
    yield put(downloadServiceRequestError(error));
  }
}

//download Service request
function* downloadJobSheetAsync(action: any) {
  try {
    // const response: Promise<any> = yield call(API.getJobsheetListAPI, { ...action.lazyState })
    const response: Promise<any> = yield call(
      API.downloadJobSheetAPI,
      action.payload
    );
    yield put(downloadJobSheetSuccess(response));
  } catch (error) {
    yield put(downloadJobSheetError(error));
  }
}
//download Estimate
function* downloadEstimateForJobSheetAsync(action: any) {
  try {
    // const response: Promise<any> = yield call(API.getJobsheetListAPI, { ...action.lazyState })
    const response: Promise<any> = yield call(
      API.downloadEstimateForJobAPI,
      action.payload
    );
    yield put(downloadEstimateJobSheetSuccess(response));
  } catch (error) {
    yield put(downloadEstimateJobSheetError(error));
  }
}
//download Invoice
function* downloadInvoiceForJobSheetAsync(action: any) {
  try {
    // const response: Promise<any> = yield call(API.getJobsheetListAPI, { ...action.lazyState })
    const response: Promise<any> = yield call(
      API.downloadInvoiceForJobsheetApi,
      action.payload
    );
    yield put(downloadInvoiceJobSheetSuccess(response));
  } catch (error) {
    yield put(downloadInvoiceJobSheetError(error));
  }
}

//download Mail Estimate
function* mailToEstimateForJobSheetAsync(action: any) {
  try {
    // const response: Promise<any> = yield call(API.getJobsheetListAPI, { ...action.lazyState })
    const response: Promise<any> = yield call(
      API.sendMailEstimateForJobAPI,
      action.payload
    );
    yield put(sendMailJobSheetSuccess(response));
  } catch (error) {
    yield put(sendMailJobSheetError(error));
  }
}

//Whatsapp Estimate
function* whatsappEstimateForJobSheetAsync(action: any) {
  try {
    // const response: Promise<any> = yield call(API.getJobsheetListAPI, { ...action.lazyState })
    const response: Promise<any> = yield call(
      API.sendWhatsappEstimateForJobAPI,
      action.payload
    );
    yield put(sendWhatsappEstimateSuccess(response));
  } catch (error) {
    yield put(sendWhatsappEstimateError(error));
  }
}
//download Mail Invoice
function* mailInvoiceForJobSheetAsync(action: any) {
  try {
    // const response: Promise<any> = yield call(API.getJobsheetListAPI, { ...action.lazyState })
    const response: Promise<any> = yield call(
      API.sendInvoiceEmailForJobsheetApi,
      action.payload
    );
    yield put(sendInvoiceMailJobSheetSuccess(response));
  } catch (error) {
    yield put(sendInvoiceMailJobSheetError(error));
  }
}

//download Mail Invoice
function* whatsappInvoiceForJobSheetAsync(action: any) {
  try {
    // const response: Promise<any> = yield call(API.getJobsheetListAPI, { ...action.lazyState })
    const response: Promise<any> = yield call(
      API.sendInvoiceWhatsappForJobsheetApi,
      action.payload
    );
    yield put(sendInvoiceWhatsappJobSheetSuccess(response));
  } catch (error) {
    yield put(sendInvoiceWhatsappJobSheetError(error));
  }
}
//download Mail Estimate
function* sendToEstimateForJobSheetAsync(action: any) {
  try {
    // const response: Promise<any> = yield call(API.getJobsheetListAPI, { ...action.lazyState })
    const response: Promise<any> = yield call(
      API.sendSMSEstimateForJobAPI,
      action.payload
    );
    yield put(sendSMSJobSheetSuccess(response));
  } catch (error) {
    yield put(sendSMSJobSheetError(error));
  }
}

//Received Payment
function* getInvoiceReceivedJobSheetAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.receivedPaymentForInvoiceAPI,
      action.payload.id
    );
    yield put(
      receivedPaymentForInvoiceJobSheetSuccess(response, action.payload.index)
    );
  } catch (error) {
    yield put(receivedPaymentForInvoiceJobSheetError(error));
  }
}

//download Mail Estimate
function* getConcerSignatureForEstimateAsync(action: any) {
  try {
    // const response: Promise<any> = yield call(API.getJobsheetListAPI, { ...action.lazyState })
    const response: Promise<any> = yield call(
      API.getConcernImageForJobAPI,
      action.payload
    );
    yield put(getEstimateConcernSignatureSuccess(response));
  } catch (error) {
    yield put(getEstimateConcernSignatureError(error));
  }
}

// =========== get prepare by user data ==============

function* getprepareByUserIdAsync(action: any) {
  try {
    const response: Promise<prepareByUserModel[]> = yield call(
      API.getPrepareyIdData,
      action.payload
    );
    yield put(getPreparebyIdSuccess(response));
  } catch (error) {
    yield put(getPreparebyIdErrro(error));
  }
}

// ===================  get updated By User  ================
function* getupdatedByUserAsync(action: any) {
  try {
    const response: Promise<updatedByUserModel[]> = yield call(
      API.getPrepareyIdData,
      action.payload
    );
    yield put(updatedByUserDetailsSuccess(response));
  } catch (error) {
    yield put(updatedByUserDetailsError(error));
  }
}

// =========== get technician by user data ==============

function* getTechnicianByUserIdAsync(action: any) {
  try {
    const response: Promise<prepareByUserModel[]> = yield call(
      API.getPrepareyIdData,
      action.payload
    );
    yield put(getTechnicianbyIdSuccess(response));
  } catch (error) {
    yield put(getTechnicianbyIdError(error));
  }
}

// ===================  get updated By User  ================
function* getEstimatorByUserAsync(action: any) {
  try {
    const response: Promise<updatedByUserModel[]> = yield call(
      API.getPrepareyIdData,
      action.payload
    );
    yield put(estimatorByUserDetailsSuccess(response));
  } catch (error) {
    yield put(estimatorByUserDetailsError(error));
  }
}

// =================== get Taxes Dropdown Async ==============

function* getTaxesDropdownAsync() {
  try {
    const response: Promise<TaxesDropdownFromJobSheetModel> = yield call(
      API.getTaxesDropDownListApi
    );
    yield put(getTaxesDropdownInJobsheetSuccess(response));
  } catch (error) {
    yield put(getTaxesDropdownInJobsheetError(error));
  }
}

// ================ franchise Arrived  =============

function* franchiseArrivedAsync(action: any) {
  try {
    debugger;
    const response: CustomResponse = yield call(
      API.franchiseArrivedApi,
      action.payload
    );
    const statusCode = response?.raw.status;
    if (statusCode == 200) {
      yield put(franchiseArrivedSuccess(response));
    } else {
    }
  } catch (error) {
    yield put(franchiseArrivedError(error));
  }
}

// ==================== franchise completed Job Async ================

function* franchisecompletedJobAsync(action: any) {
  debugger;
  try {
    let payload: any = { ...action.payload };
    delete payload["pendingCommentsDto"];
    // delete payload["incomingDispatch"];
    let isNextState = action.payload.IsAfcsGenerated;
    delete payload["IsAfcsGenerated"];
    let pendingCommandPayload: any = action.payload?.pendingCommentsDto?.map(
      (eachPending: any) => {
        let data: any = {};
        (data["jobId"] = action.payload.id), (data["id"] = eachPending.id);
        data["pendingReasonId"] = eachPending.pendingReasonId;
        data["pendingInformation"] = eachPending.pendingInformation;
        data["commentedAt"] = eachPending.commentedAt;
        return data;
      }
    );
    payload["pendingComments"] = [...pendingCommandPayload];
    delete payload["estimate"];
    let readyToInvoicePayload = payload["readyToInvoice"];
    delete payload["readyToInvoice"];
    readyToInvoicePayload["id"] = action.payload.id;
    delete payload["outgoingDispatch"];
    // const additionalExpensesPayload = action.payload?.additionalExpenses
    const additionalExpensesPayload =
      action.payload?.additionalExpensesNewFormat?.map((item: any) => {
        const { document, ...rest } = item;
        return rest;
      });

    if (action.payload.files?.length > 0) {
      const response: CustomResponse = yield call(
        API.franchiseUploadDocumentApi,
        action.payload.id,
        action.payload.files,
        action.payload.files?.type
      );
      const statusCode = response?.raw.status;
      if (statusCode == 204) {
        if (additionalExpensesPayload?.length > 0) {
          const response: CustomResponse = yield call(
            API.additionalExpenseApi,
            additionalExpensesPayload,
            action.payload.id
          );
          const statusCode = response?.raw.status;
          if (statusCode == 204) {
            const { data } = yield call(API.updateJobsheetAPI, payload);
            // if (data && action.payload.franchiseArrived == true && action.payload.franchiseArrivedOrNot == false) {
            //   const payloadApi = {
            //     "id": action.payload.id,
            //     "franchiseArrivedTime": null,
            //     "franchiseArrivedLocation": `${action.payload.franchiseCurrentLat},${action.payload.franchiseCurrentLag}`
            //   }
            //   const { data } = yield call(API.franchiseArrivedApi, payloadApi)
            //   if (data && isNextState) {
            //     const { data } = yield call(API.franchiseJobClosedStatusAPI, action.payload.id,);
            //     yield put(franchiseCompletedJobSuccess(data));
            //   }
            //   else {
            //     yield put(franchiseCompletedJobSuccess(data));
            //   }
            // } else {
            //   if (data && isNextState) {
            //     const { data } = yield call(API.franchiseJobClosedStatusAPI, action.payload.id,);
            //     yield put(franchiseCompletedJobSuccess(data));
            //   }
            //   else {
            //     yield put(franchiseCompletedJobSuccess(data));
            //   }
            // }
            if (data && isNextState) {
              const { data } = yield call(
                API.franchiseJobClosedStatusAPI,
                action.payload.id,
                action.payload?.jobCloseOtp
              );
              yield put(franchiseCompletedJobSuccess(data));
            } else {
              yield put(franchiseCompletedJobSuccess(data));
            }
          }
        } else {
          const { data } = yield call(API.updateJobsheetAPI, payload);
          if (data && isNextState) {
            const { data } = yield call(
              API.franchiseJobClosedStatusAPI,
              action.payload.id,
              action.payload?.jobCloseOtp
            );
            yield put(franchiseCompletedJobSuccess(data));
          } else {
            yield put(franchiseCompletedJobSuccess(data));
          }
        }
      }
    }
    // else if (action.payload.perfectionFiles?.length > 0) {
    //   const response: CustomResponse = yield call(API.franchiseUploadDocumentApi, action.payload.id, action.payload.perfectionFiles, action.payload.perfectionFiles?.type)
    //   const statusCode = response?.raw.status;
    //   if (statusCode == 204) {
    //     if (additionalExpensesPayload?.length > 0) {
    //       const response: CustomResponse = yield call(API.additionalExpenseApi, additionalExpensesPayload, action.payload.id)
    //       const statusCode = response?.raw.status;
    //       if (statusCode == 204) {
    //         const { data } = yield call(API.updateJobsheetAPI, payload);
    //         if (data && isNextState) {
    //           const { data } = yield call(API.franchiseJobClosedStatusAPI, action.payload.id,action.payload?.jobCloseOtp);
    //           yield put(franchiseCompletedJobSuccess(data));
    //         } else {
    //           yield put(franchiseCompletedJobSuccess(data));
    //         }
    //       }
    //     } else {
    //       const { data } = yield call(API.updateJobsheetAPI, payload);
    //       if (data && isNextState) {
    //         const { data } = yield call(API.franchiseJobClosedStatusAPI, action.payload.id,action.payload?.jobCloseOtp);
    //         yield put(franchiseCompletedJobSuccess(data));
    //       }
    //       else {
    //         yield put(franchiseCompletedJobSuccess(data));
    //       }
    //     }
    //   }
    // }
    else {
      if (additionalExpensesPayload?.length > 0) {
        const response: CustomResponse = yield call(
          API.additionalExpenseApi,
          additionalExpensesPayload,
          action.payload.id
        );
        const statusCode = response?.raw.status;
        if (statusCode == 204) {
          const { data } = yield call(API.updateJobsheetAPI, payload);
          // if (data && action.payload.franchiseArrived == true && action.payload.franchiseArrivedOrNot == false) {
          //   const payloadApi = {
          //     "id": action.payload.id,
          //     "franchiseArrivedTime": null,
          //     "franchiseArrivedLocation": `${action.payload.franchiseCurrentLat},${action.payload.franchiseCurrentLag}`
          //   }
          //   const { data } = yield call(API.franchiseArrivedApi, payloadApi)
          //   if (data && isNextState) {
          //     const { data } = yield call(API.franchiseJobClosedStatusAPI, action.payload.id,);
          //     yield put(franchiseCompletedJobSuccess(data));
          //   }
          //   else {
          //     yield put(franchiseCompletedJobSuccess(data));
          //   }
          // } else {
          //   if (data && isNextState) {
          //     const { data } = yield call(API.franchiseJobClosedStatusAPI, action.payload.id,);
          //     yield put(franchiseCompletedJobSuccess(data));
          //   } else {
          //     yield put(franchiseCompletedJobSuccess(data));
          //   }
          // }
          if (data && isNextState) {
            const { data } = yield call(
              API.franchiseJobClosedStatusAPI,
              action.payload.id,
              action.payload?.jobCloseOtp
            );
            yield put(franchiseCompletedJobSuccess(data));
          } else {
            yield put(franchiseCompletedJobSuccess(data));
          }
        }
      } else {
        const { data } = yield call(API.updateJobsheetAPI, payload);
        if (data && isNextState) {
          const { data } = yield call(
            API.franchiseJobClosedStatusAPI,
            action.payload.id,
            action.payload?.jobCloseOtp
          );
          yield put(franchiseCompletedJobSuccess(data));
        } else {
          yield put(franchiseCompletedJobSuccess(data));
        }
      }
    }
  } catch (error) {
    yield put(franchiseCompletedJobError(error));
  }
}

//get lat and lag
function* getlatLngAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getLatlngApi, action.payload);
    yield put(customerOrDelaerLatLagSuccess(response, action.payload));
  } catch (error) {
    yield put(customerOrDelaerLatLagError(error));
  }
}

//get additional expense document
// function* additionalExpensesDocumentAsync(action: any) {
//   try {
//     const response: Promise<any> = yield call(API.franchiseUploadDocumentApi, action.payload.id, action.payload.formData)
//     yield put(additionalExpensedocumentuploadSuccess(response))
//   } catch (error) {
//     yield put(additionalExpensedocumentuploadError(error))
//   }
// }

//get additional expense list
function* additionalExpensesListAsync(action: any) {
  try {
    // const response: getadditionaldetails = yield call(API.getAdditionalPagesAPI, { ...action.lazyState })
    const response: getadditionaldetails = yield call(
      API.getAdditionalPagesAPI,
      action.lazyState
    );
    // if (response.data.items.length > 0) {
    //   yield put(getFranchiseUploadedDocumentLoading(response.data.items[0].jobId));
    // }
    yield put(additionalExpenseGridSuccess(response));
  } catch (error) {
    yield put(additionalExpenseGridError(error));
  }
}

//get additional expense approve
function* additionalExpensesApproveAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.additionalExpenseApproveAPI,
      action.payload
    );
    yield put(additionalExpenseApproveSuccess(response));
  } catch (error) {
    yield put(additionalExpenseApproveError(error));
  }
}

//get additional expense details
function* getadditionalDetailsAsync(action: any) {
  try {
    const response: Apiresponse = yield call(
      API.additionalExpenseDetailsAPI,
      action.payload
    );
    if (response.data.jobId) {
      yield put(getFranchiseUploadedDocumentLoading(response.data.jobId));
    }
    yield put(getAdditionalExpenseDetailsSuccess(response));
  } catch (error) {
    yield put(getAdditionalExpenseDetailsError(error));
  }
}

//getFranchiseDoucumnetAsync
function* getFranchiseDoucumnetAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.getFranchiseDoucumentForexpenses,
      action.payload
    );
    yield put(getFranchiseUploadedDocumentSuccess(response));
  } catch (error) {
    yield put(getFranchiseUploadedDocumentError(error));
  }
}

// getFranchiseDoucumnetLinkAsync
function* getFranchiseDoucumnetLinkAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.getFranchiseDoucumentLinkApi,
      action.payload
    );
    yield put(getFranchiseUploadedDocumentLinkSuccess(response));
  } catch (error) {
    yield put(getFranchiseUploadedDocumentLinkError(error));
  }
}
// getFranchiseDoucumnetLinkAsync
function* setJobsheetStatusAsync(action: any) {
  try {
    yield put(setJobsheetsStatusSuccess(action));
  } catch (error) { }
}
function* getJobsheetStatusAsync(action: any) {
  try {
    yield put(getJobsheetsStatusSuccess(action));
  } catch (error) { }
}

function* additionalExpenseUpdateAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.additionalExpenseUpdateAPI,
      action.payload.id,
      action.payload.body
    );
    yield put(additionalExpenseUpdateSuccess(response));
  } catch (error) {
    yield put(additionalExpenseUpdateError(error));
  }
}

function* getEnquiryListAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.getJobEnquiryListForJobsheetViewApi,
      {
        ...action.payload,
      }
    );
    yield put(getJobsheetEnquiryByIdSuccess(response));
  } catch (error) {
    yield put(getJobsheetEnquiryByIdError(error));
  }
}

// ============ print Exchange Invoice  ==========

function* printExchangeInvoiceAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.printExchangeInvoiceAPI,
      action.payload
    );
    yield put(printExchangeInvoiceSuccess(response));
  } catch (error) {
    yield put(printExchangeInvoiceError(error));
  }
}

// ============ email Exchange Invoice  ============

function* emailExchangeInvoiceAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.emailExchangeInvoiceAPI,
      action.payload
    );
    yield put(emailExchangeInvoiceSuccess(response));
  } catch (error) {
    yield put(emailExchangeInvoiceError(error));
  }
}

function* whatsappExchangeInvoiceAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.whatsappExchangeInvoiceAPI,
      action.payload
    );
    yield put(whatsappExchangeInvoiceSuccess(response));
  } catch (error) {
    yield put(whatsappExchangeInvoiceError(error));
  }
}

// =============== delete Additional Expense Async =========

function* deleteAdditionalExpenseAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.deleteAdditionalAPI,
      action.payload.id
    );
    yield put(deleteAdditionalExpensesDocumentSuccess(response));
    delay(2000);
    yield put(getAdditionalExpenseWithoutqueryLoading(action.payload.jobId));
  } catch (error) {
    yield put(deleteAdditionalExpensesDocumentError(error));
  }
}

function* getAdditionalExpenseWithoutQueryAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.getAdditionalExpenseWithoutQueryAPI,
      action.payload
    );
    yield put(getAdditionalExpenseWithoutquerySuccess(response));
  } catch (error) {
    yield put(getAdditionalExpenseWithoutqueryError(error));
  }
}

// ================== getApproveDocumentAsync =============

function* getApproveDocumentAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.getApproveDoucumentApi,
      action.payload
    );
    yield put(getApproveDocumentSuccess(response));
  } catch (error) {
    yield put(getApproveDocumentError(error));
  }
}

// ================ get approve document link  ==============
function* getApproveDocumentLinkAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.getFranchiseDoucumentLinkApi,
      action.payload
    );
    yield put(getApproveDocumentLinkSuccess(response));
  } catch (error) {
    yield put(getApproveDocumentLinkError(error));
  }
}

// ================ franchise Document Delete Async ==============
function* franchiseDocumentDeleteAsync(action: any) {
  try {
    const response: CustomResponse = yield call(
      API.deleteAdditionalDocumentAPI,
      action.payload.id
    );
    const statusCode = response?.raw.status;
    if (statusCode == 204) {
      yield put(getFranchiseUploadedDocumentLoading(action.payload.jobId));
      yield put(franchiseUploadedDocumentDeleteSuccess(response));
    } else {
      console.log("franchise deleted file error");
    }
  } catch (error) {
    yield put(franchiseUploadedDocumentDeleteError(error));
  }
}

// ================ get additional expenses document link  ==============
function* getAdditionalExpensesDocumentLinkAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.getAdditionalExpensesDoucumentLinkApi,
      action.payload
    );
    yield put(additionalExpensesDocumentLinkSuccess(response));
  } catch (error) {
    yield put(additionalExpensesDocumentLinkError(error));
  }
}

// ============== Get feedback pending List For Jobsheet =============

function* getfeedbackpendingListForJobsheetAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.getJobPagesforFeedbackListApi,
      action.payload.dealerOrCustomerId,
      action.payload.feedbackStatus,
      action.payload.job_status
    );
    yield put(getJobsheetFeedbackPendingListSuccess(response));
  } catch (error) {
    yield put(getJobsheetFeedbackPendingListError(error));
  }
}

// ================= dealer feedback pending boolean check  ================

function* isDealeHaveFeedbackPendingAsync(action: any) {
  debugger;
  try {
    const { data } = yield call(API.getUserTypeIdByuseUuidApi, action.payload);
    if (data) {
      if (data?.extraProperties?.UserType == "Dealer") {
        try {
          const response: Promise<any> = yield call(
            API.getDealer,
            data?.extraProperties?.DealerId
          );
          yield put(checkDealerHaveFeedbackPendingSuccess(response));
        } catch (error) {
          yield put(checkDealerHaveFeedbackPendingError(error));
        }
      } else if (data?.extraProperties?.UserType == "Customer") {
        try {
          const response: Promise<any> = yield call(
            API.getIndividualCustomer,
            data?.extraProperties?.CustomerId
          );
          yield put(checkDealerHaveFeedbackPendingSuccess(response));
        } catch (error) {
          yield put(checkDealerHaveFeedbackPendingError(error));
        }
      } else {
      }
    } else {
      console.log("error");
    }
  } catch (error) {
    yield put(checkDealerHaveFeedbackPendingError(error));
  }
}

function* getCustomerDetailsAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.getCustomerFromJobsheetApi,
      action.payload.currentValue,
      action.payload.type
    );
    yield put(getCustmerDetailsInJobsheetSuccess(response));
  } catch (error) {
    yield put(getCustmerDetailsInJobsheetError(error));
  }
}
function* fetchProfiles(action: any) {
  try {
    const response: Promise<any> = yield call(API.getProfile);
    yield put(getCustmerProfileSuccess(response));
  } catch (error) {
    yield put(getCustmerProfileError(error));
  }
}

function* getCurrentAddressLatLngAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getLatlngApi, action.payload);
    yield put(getUserCurretnAddressLatLngSuccess(response));
  } catch (error) {
    yield put(getUserCurretnAddressLatLngError(error));
  }
}

function* getJobsheetNumberAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getJobsheetForFilter);
    yield put(getJobsheetNumberSuccess(response));
  } catch (error) {
    yield put(getJobsheetNumberError(error));
  }
}

function* getAudioModelAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getAudioModelForFilter);
    yield put(getAudioModelSuccess(response));
  } catch (error) {
    yield put(getAudioModelError(error));
  }
}


function* newAdditionalUpdateAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.newadditionalExpenseUpdateAPI, action.payload.id, action.payload.content);
    yield put(newAdditionalExpensesUpdateSuccess(response));
  } catch (error) {
    yield put(newAdditionalExpensesUpdateError(error));
  }
}

function* getAdditionalExpenseUserDetailsAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getUserTypeIdByuseUuidApi, action.payload);
    yield put(getAdditionalExpenseUserDetailsSuccess(response));
  } catch (error) {
    yield put(getAdditionalExpenseUserDetailsError(error));
  }
}

function* emailServiceHistoryAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.emailServiceHistoryAPI,action.payload);
    yield put(emailServiceHistorySuccess(response));
  } catch (error) {
    yield put(emailServiceHistoryError(error));
  }
}

function* printServiceHistoryAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.printServiceHistoryAPI,action.payload);
    yield put(printServiceHistorySuccess(response));
  } catch (error) {
    yield put(printServiceHistoryError(error));
  }
}



//watcher function
function* jobsheetSaga() {
  yield takeLatest(JobSheetTypes.GET_JOBSHEETS_LIST, getJobSheetListAsync);
  yield takeLatest(JobSheetTypes.DELETE_JOBSHEETS, deleteJobSheetAsync);
  yield takeEvery(JobSheetTypes.GET_JOBSHEETBYID, fetchJobsheet);
  yield takeLatest(JobSheetTypes.CREATE_JOBSHEET_LOADING, createJobSheetAsync);
  yield takeLatest(JobSheetTypes.DEALERDROPDOWNLIST_LOADING, getDealerListAsync);
  yield takeLatest(JobSheetTypes.GETVEHICLEBRAND_RELATEDTO_DEALER_LOADING, getDealerVehiclebrandListAsync);
  yield takeLatest(JobSheetTypes.GETCUSTOMER_DROPDOWNLIST_LOADING, getcustomerDropdownListAsync);
  yield takeLatest(JobSheetTypes.GET_AUDIOMODEL_JOB_DROPDOWNLIST_LOADING, getAudioModelDropdwonListAsync);
  yield takeLatest(JobSheetTypes.GET_PENDING_REASON_LOADING, getPendingReasonLoading);
  yield takeLatest(JobSheetTypes.GET_AUDIO_MODEL_DETAILS_FOR_JOBSHEET_LOADING, getAudioModelDetailsAsync);
  yield takeLatest(JobSheetTypes.REPLACEMENT_SYSTEM_DISPATCH, updateReplacementSystemDispatchStatus);
  yield takeLatest(JobSheetTypes.CREATE_JOB, updateCreateJobStatus);
  yield takeLatest(JobSheetTypes.ASSIGN_TO_ESTIMATE_JOB, updateAssignToEstimateJobStatus);
  yield takeLatest(JobSheetTypes.ESTIMATE_COMPLETE_JOB, updateEstimateCompleteJobStatus);
  yield takeLatest(JobSheetTypes.ESTIMATE_APPROVAL_JOB, updateEstimateApprovalJobStatus);
  yield takeLatest(JobSheetTypes.CANCEL_JOB, updateCancelJobStatus);
  yield takeLatest(JobSheetTypes.ZERO_ESTIMATE_ASSIGN_TO_TECHNICIAN, updateZeroEstimateAssignToTechnicianJobStatus);
  yield takeLatest(JobSheetTypes.ASSIGN_TO_TECHNICIAN, updateAssignToTechnicianJobStatus);
  yield takeLatest(JobSheetTypes.READY_TO_INVOICE, updateReadyToInvoiceStatus);
  yield takeLatest(JobSheetTypes.JOB_INVOICED, updateJobInvoicedStatus);
  yield takeLatest(JobSheetTypes.JOB_CLOSE, updateJobClosedStatus);
  yield takeLatest(JobSheetTypes.GET_EMPLOYEE_DROPDOWN_LOADING, getEmployeeDropdownForJobAsync);
  yield takeLatest(JobSheetTypes.GET_COURIER_DROPDOWN_LOADING, getCourierDropdownForJobAsync);
  yield takeLatest(JobSheetTypes.GET_COURIER_DROPDOWN_LOADING, getCourierDropdownForAllDataJobAsync);
  // yield takeLatest(JobSheetTypes.UPDATE_PENDING_COMMAND_LOADING, updatePendingCommandJob)
  yield takeLatest(JobSheetTypes.GET_JOBSHEET_TECHNICIAN_DROPDOWN_LOADING, getTechnicianDropdownAsync);
  yield takeLatest(JobSheetTypes.GET_JOBSHEET_ESTIMATOR_DROPDOWN_LOADING, getEstimatorDropdownAsync);
  yield takeLatest(JobSheetTypes.GET_JOBSHEET_VEHICLEBRAND_DROPDOWN_LOADING, getVehiclebrandDropdownAsync);
  yield takeLatest(JobSheetTypes.GET_PARTS_DROPDOWN_LOADING, getPartsDropdownForJobAsync);
  yield takeLatest(JobSheetTypes.GET_PART_DETAILS_FOR_JOB, getPartsDetailsForJobAsync);
  yield takeLatest(JobSheetTypes.GET_TAXES_DROPDOWN_LOADING, getTaxListForJobAsync);
  yield takeLatest(JobSheetTypes.GET_TAX_DETAILS_LOADING, getTaxDetailsForJobAsync);
  yield takeLatest(JobSheetTypes.DELETE_PENDING_COMMAND_LOADING, deletePendingCommandsAsync);
  yield takeLatest(JobSheetTypes.GET_INVOICE_DETAILS_BY_JOB_ID_LOADING, getInvoiceByJobIdAsync);
  yield takeLatest(JobSheetTypes.GET_JOBSHEETS_HISTORY_DATA, getJobSheetHistoryListAsync);
  yield takeLatest(JobSheetTypes.DOWNLAOAD_SERVICE_REQUEST_FOR_JOB, printServiceRequestForJobAsync);
  yield takeLatest(JobSheetTypes.DOWNLAOAD_JOB_SHEET, downloadJobSheetAsync);
  yield takeEvery(JobSheetTypes.GET_INVOICE_RECEIVED_PAYMNET, getInvoiceReceivedJobSheetAsync);
  yield takeLatest(JobSheetTypes.DOWNLAOAD_ESTIMATE_FOR_JOB_SHEET, downloadEstimateForJobSheetAsync);
  yield takeLatest(JobSheetTypes.DOWNLOAD_INVOICE_FOR_JOB_SHEET, downloadInvoiceForJobSheetAsync);
  yield takeLatest(JobSheetTypes.SEND_EMAIL_ESTIMATE_FOR_JOB_SHEET, mailToEstimateForJobSheetAsync);
  yield takeLatest(JobSheetTypes.SEND_WHATSAPP_ESTIMATE_FOR_JOB_SHEET, whatsappEstimateForJobSheetAsync);
  yield takeLatest(JobSheetTypes.SEND_INVOICE_EMAIL_FOR_JOB_SHEET, mailInvoiceForJobSheetAsync);
  yield takeLatest(JobSheetTypes.SEND_INVOICE_WHATSAPP_FOR_JOB_SHEET, whatsappInvoiceForJobSheetAsync);
  yield takeLatest(JobSheetTypes.SEND_SMS_ESTIMATE_FOR_JOB_SHEET, sendToEstimateForJobSheetAsync);
  yield takeLatest(JobSheetTypes.GET_ESTIMATE_CONCERN_IMAGE, getConcerSignatureForEstimateAsync);
  yield takeLatest(JobSheetTypes.GET_PREPARED_BYID_LOADING, getprepareByUserIdAsync);
  yield takeLatest(JobSheetTypes.GET_UPDATED_BYID_USER_LOADING, getupdatedByUserAsync);
  yield takeLatest(JobSheetTypes.GET_TECHNICIAN_BYID_LOADING, getTechnicianByUserIdAsync);
  yield takeLatest(JobSheetTypes.GET_ESTIMATOR_BYID_USER_LOADING, getEstimatorByUserAsync);
  yield takeLatest(JobSheetTypes.GET_TAXES_TYPES_IN_JOBSHEET_LOADING, getTaxesDropdownAsync);
  yield takeLatest(JobSheetTypes.FRANCHISEE_ARRIVED_LOADING, franchiseArrivedAsync);
  yield takeLatest(JobSheetTypes.FRANCHISEE_COMPLETED_JOB_LOADING, franchisecompletedJobAsync);
  yield takeLatest(JobSheetTypes.GET_DELAER_OR_CUSTOMER_LOCATION_LOADING, getlatLngAsync);
  // yield takeLatest(JobSheetTypes.ADDITIONAL_EXPENSES_DOCUMENT_UPLOAD_LOADING, additionalExpensesDocumentAsync)
  yield takeLatest(JobSheetTypes.ADDITIONAL_EXPENSES_GRID_LOADING, additionalExpensesListAsync);
  yield takeLatest(JobSheetTypes.ADDITIONAL_EXPENSES_APPROVE_LOADING, additionalExpensesApproveAsync);
  yield takeLatest(JobSheetTypes.GET_ADDITIONAL_EXPENSES_DETAILS_LOADING, getadditionalDetailsAsync);
  yield takeLatest(JobSheetTypes.GET_FRANCHISE_UPLOADED_DOCUMENT_LOADING, getFranchiseDoucumnetAsync);
  yield takeLatest(JobSheetTypes.GET_FRANCHISE_UPLOADED_DOCUMENT_LINK_LOADING, getFranchiseDoucumnetLinkAsync);
  yield takeLatest(JobSheetTypes.GET_JOBSHEET_STATUS, getJobsheetStatusAsync);
  yield takeLatest(JobSheetTypes.SET_JOBSHEET_STATUS, setJobsheetStatusAsync);
  yield takeLatest(JobSheetTypes.ADDITIONAL_EXPENSES_UPDATE_LOADING, additionalExpenseUpdateAsync);
  yield takeLatest(JobSheetTypes.GET_JOBSHEET_ENQUIRY_BY_ID, getEnquiryListAsync);
  yield takeLatest(JobSheetTypes.PRINT_EXCHANGE_INVOICE_LOADING, printExchangeInvoiceAsync);
  yield takeLatest(JobSheetTypes.EMAIL_EXCHNAGE_INVOICE_LOADING, emailExchangeInvoiceAsync);
  yield takeLatest(JobSheetTypes.WHATSAPP_EXCHNAGE_INVOICE_LOADING, whatsappExchangeInvoiceAsync);
  yield takeLatest(JobSheetTypes.DELETE_ADDITIONAL_EXPENSES_LOADING, deleteAdditionalExpenseAsync);
  yield takeLatest(JobSheetTypes.GET_ADDITIONAL_EXPENSES_WITHOUT_QUERY_LOADING, getAdditionalExpenseWithoutQueryAsync);
  yield takeLatest(JobSheetTypes.GET_APPROVE_DOCUMENT_LOADING, getApproveDocumentAsync);
  yield takeLatest(JobSheetTypes.GET_APPROVE_DOCUMENT_LINK_LOADING, getApproveDocumentLinkAsync);
  yield takeLatest(JobSheetTypes.FRANCHISE_DOCUMENT_DELETE_LOADING, franchiseDocumentDeleteAsync);
  yield takeLatest(JobSheetTypes.GET_ADDITIONAL_EXPENSE_DOCUMENT_LINK_LOADING, getAdditionalExpensesDocumentLinkAsync);
  yield takeLatest(JobSheetTypes.GET_DEALER_OR_CUSTOMER_FEEDBACK_JOB_SHEET_LIST_LOADING, getfeedbackpendingListForJobsheetAsync);
  yield takeLatest(JobSheetTypes.CHECK_DEALER_HAVE_FEEDBACK_PENDING_LOADING, isDealeHaveFeedbackPendingAsync);
  yield takeLatest(JobSheetTypes.GET_CUSTOMER_STATUS_IN_JOBSHEET_LOADING, getCustomerDetailsAsync);
  yield takeLatest(JobSheetTypes.GET_CUSTOMER_PROFILE_LOADING, fetchProfiles);
  yield takeLatest(JobSheetTypes.GET_CURRENT_ADDRESS_LAT_LNG_LOADING, getCurrentAddressLatLngAsync);
  yield takeLatest(JobSheetTypes.GET_JOBSHEET_NUMBER_LOADING, getJobsheetNumberAsync);
  yield takeLatest(JobSheetTypes.GET_AUDIO_MODEL_LOADING, getAudioModelAsync);
  yield takeLatest(JobSheetTypes.NEW_ADDITIONAL_EXPENSES_UPDATED_LOADING, newAdditionalUpdateAsync);
  yield takeLatest(JobSheetTypes.GET_ADDITIONAL_EXPENSE_USER_DETAILS_LOADING, getAdditionalExpenseUserDetailsAsync);
  yield takeLatest(JobSheetTypes.EMAIL_SERVICE_HISTORY_LOADING, emailServiceHistoryAsync);
  yield takeLatest(JobSheetTypes.PRINT_SERVICE_HISTORY_LOADING, printServiceHistoryAsync);
}

export default jobsheetSaga;
