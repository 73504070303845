export enum ROUTER {

  // ID
  ID = "/:id",
  SUB_ID = "/:sub_id",
  JOB_ID = "/:job_id",
  EDIT_JOB_ID = "/:edit_job_id",
  CREATE_CUSTOMER_ID = "/:customer_Id",
  PARTS_SKU_ID = "/:sku_id",

  //PROFILE
  PROFILE = "/profile",
  PROFILE_EDIT = "/profile/edit",
  PROFILE_EMPLOYEE = "/profile/employee",

  //Profile-Attendance
  ATTENDANCE = "/profile/attendance",

  //SideMenu-Attendance
  SHOW_ATTENDANCE = "/attendance",

  //JOB SHEETS
  JOB_SHEETS_GRID = "/job_sheets",
  JOB_SHEETS_EDIT = "/job_sheets/edit",
  JOB_SHEETS_VIEW = "/job_sheets/view",
  JOB_SHEETS_CREATE = "/job_sheets/create",

  //Additional expense
  ADDITIONAL_EXPENSE_GRID = "/additional_expense",
  ADDITIONAL_EXPENSE_VIEW = "/additional_expense/view",
  ADDITIONAL_EXPENSE_EDIT = "/additional_expense/edit",

  //enquiry
  ENQUIRY_GRID = "/job_sheets_enquiry",
  ENQUIRY_VIEW = "/job_sheets_enquiry/view",
  ENQUIRY_CREATE = "/job_sheets_enquiry/create",
  

  //feedback
  FEEDBACK_GRID = "/feedback",
  FEEDBACK_CREATE = "/feedback/create",
  FEEDBACK_VIEW = "/feedback/view",

  // AUDIO BRANDS
  AUDIO_BRANDS_GRID = "/audio_brands",
  AUDIO_BRANDS_EDIT = "/audio_brands/edit",
  AUDIO_BRANDS_VIEW = "/audio_brands/view",
  AUDIO_BRANDS_CREATE = "/audio_brands/create",

  // AUDIO MODELS
  AUDIO_MODELS_GRID = "/audio_models",
  AUDIO_MODELS_EDIT = "/audio_models/edit",
  AUDIO_MODELS_VIEW = "/audio_models/view",
  AUDIO_MODELS_CREATE = "/audio_models/create",

  // PARTS

  PARTS_GRID = "/parts",
  PARTS_EDIT = "/parts/edit",
  PARTS_VIEW = "/parts/view",
  PARTS_CREATE = "/parts/create",


  // VEHICLE BRANDS
  VEHICLE_BRANDS_GRID = "/vehicle_brands",
  VEHICLE_BRANDS_EDIT = "/vehicle_brands/edit",
  VEHICLE_BRANDS_VIEW = "/vehicle_brands/view",
  VEHICLE_BRANDS_CREATE = "/vehicle_brands/create",

  // SUPPLIER
  SUPPLIER_GRID = "/suppliers",
  SUPPLIER_EDIT = "/suppliers/edit",
  SUPPLIER_VIEW = "/suppliers/view",
  SUPPLIER_CREATE = "/suppliers/create",

  // TAXES
  TAXES_GRID = "/taxes",
  TAXES_EDIT = "/taxes/edit",
  TAXES_VIEW = "/taxes/view",
  TAXES_CREATE = "/taxes/create",

  // PARTS SKU
  PARTS_SKU = "/part_skus",
  PARTS_SKU_PRINT = "/part_sku_print",


  // USERS
  USERS_GRID = "/employee",
  USERS_EDIT = "/employee/edit",
  USERS_VIEW = "/employee/view",
  USERS_CREATE = "/employee/create",

  // USERS
  INTENT_GRID = "/intend",
  INTENT_EDIT = "/intend/edit",
  INTENT_VIEW = "/intend/view",
  INTENT_CREATE = "/intend/create",
  INTENT_SCAN = "/intend/scan",

  // CUSTOMERS

  CUSTOMERS_GRID = "/customers",
  CUSTOMERS_EDIT = "/customers/edit",
  CUSTOMERS_VIEW = "/customers/view",
  CUSTOMERS_CREATE = "/customers/create",

  // DEALERS

  DEALERS_GRID = "/dealers",
  DEALERS_EDIT = "/dealers/edit",
  DEALERS_VIEW = "/dealers/view",
  DEALERS_CREATE = "/dealers/create",

  // ADMINISTRATOR
  ADMINISTRATOR_GRID = "/administrators",
  ADMINISTRATOR_EDIT = "/administrators/edit",
  ADMINISTRATOR_VIEW = "/administrators/view",
  ADMINISTRATOR_CREATE = "/administrators/create",

  //FRANCHISEE
  FRANCHISEE_CREATE = "/franchise/create",
  FRANCHISEE_GRID = "/franchise",
  FRANCHISEE_VIEW = "/franchise/view",
  FRANCHISEE_EDIT = "/franchise/edit",

  //Franchise Profile
  FRANCHISEE_PROFILE_VIEW = "/profile/franchise",


  //franchise_subuser Management
  SUB_USER_CREATE = "/franchise_subuser/create",
  SUB_USER_CREATE_ID = "/franchise_subuser/create",
  SUB_USER_GRID = "/franchise_subuser",
  SUB_USER_VIEW = "/franchise_subuser/view",
  SUB_USER_EDIT = "/franchise_subuser/edit",

  //dealer_subuser Management

  DEALER_SUB_USER_GRID = "/dealer_subuser",
  DEALER_SUB_USER_CREATE = "/dealer_subuser/create",
  DEALER_SUB_USER_CREATE_ID = "/dealer_subuser/create",
  DEALER_SUB_USER_EDIT = "/dealer_subuser/edit",
  DEALER_SUB_USER_VIEW = "/dealer_subuser/view",

  //stockmanagement

  //purchase order
  PURCHASE_ORDER_GRID = '/purchase_order',
  PURCHASE_ORDER_CREATE = '/purchase_order/create',
  PURCHASE_ORDER_EDIT = '/purchase_order/edit',
  PURCHASE_ORDER_VIEW = '/purchase_order/view',

  //parts inbount
  PARTS_INBOUND_GRID = '/parts_inbound',
  PARTS_INBOUND_CREATE = '/parts_inbound/create',
  PARTS_INBOUND_EDIT = '/parts_inbound/edit',
  PARTS_INBOUND_VIEW = '/parts_inbound/view',
  PARTS_SKU_GENERATOR = '/parts_sku_generator',

  //stock adjustment

  STOCK_ADJUSTMENT_GRID = '/stock_adjustment',
  STOCK_ADJUSTMENT_CREATE = '/stock_adjustment/create',
  STOCK_ADJUSTMENT_EDIT = '/stock_adjustment/edit',
  STOCK_ADJUSTMENT_VIEW = '/stock_adjustment/view',
  STOCK_ADJUSTMENT_PART_VIEW = '/stock_adjustment/part/view',

  //stock register
  STOCK_REGISTER_GRID = '/stock_register',



  // Stock value by part
  STOCK_VALUE_BY_PART_GRID = "/stock_value_by_part",
  // Stock value by QTE
  STOCK_QUANTITY_BY_PART_GRID = "/stock_qty_by_part",

  //stock value by supplier
  STOCK_VALUE_BY_SUPPLIER_GRID = "/stock_value_by_supplier",

  //stock quantity by supplier
  STOCK_QUANTITY_BY_SUPPLIER_GRID = "/stock_quantity_by_supplier",

  //stock value by music system brand 
  STOCK_VALUE_BY_MUSIC_SYSTEM_BRAND_GRID = "/stock_value_by_musicSystem_brand",
  //stock quantity by music system brand 
  STOCK_QUANTITY_BY_MUSIC_SYSTEM_BRAND_GRID = "/stock_quantity_by_musicSystem_brand",

  //stock inspection
  STOCK_INSPECTION_GRID = '/stock_inspection',
  STOCK_INSPECTION_CREATE = '/stock_inspection/create',
  STOCK_INSPECTION_EDIT = '/stock_inspection/edit',
  STOCK_INSPECTION_VIEW = '/stock_inspection/view',

  STOCK_LOCATION_GRID = '/stock_location',
  STOCK_LOCATION_CREATE = '/stock_location/create',
  STOCK_LOCATION_EDIT = '/stock_location/edit',
  STOCK_LOCATION_VIEW = '/stock_location/view',

  //invoice list
  INVOICE_GRID = "/invoices",
  INVOICE_CREATE = '/invoice/create',
  INVOICE_EDIT = '/invoice/edit',
  INVOICE_VIEW = '/invoice/view',
  INVOICE_VIEW_BY_PART = '/invoice/part/view',



  //stock reports
  // Invoice Item Wise Registers
  INVOICE_ITEM_WISE_REGISTERS_GRID = '/reports_invoice_item_wise_registers',
  JOB_SHEET_REPORTS = "/job_sheet_reports",
  JOB_SHEET_TAT_REPORTS = "/jobSheet_tat_reports",
  PENDING_JOB_SHEET_TAT_REPORTS = "/pending_jobSheet_tat_reports",
  MONTHLY_PARTS_SUMMARY = "/monthly_parts_summary",
  ATTENDANCE_REPORT = "/attendance_reports",


  //pending parts
  PENDING_PARTS = '/pending_parts',
  PENDING_PARTS_CONSOLIDATED = '/pending_parts_consolidated',
  //dispatch
  OUTCOMING_DISPATCH = "/outcoming_dispatches",
  VIEW_OUTGOING_DISPATCH = "/outcoming_dispatches/view",
  INCOMING_DISPATCH = '/incoming_dispatches',
  VIEW_INCOMING_DISPATCH = '/incoming_dispatches/view',


  //courier
  COURIER_LIST = "/couriers",
  VIEW_COURIER = "/couriers/view",
  EDIT_COURIER = "/couriers/edit",
  CREATE_COURIER = "/couriers/create",

  //recieved payment
  RECEIVED_PAYMENT_LIST = "/received_payments",
  RECEIVED_PAYMENT_VIEW = "/received_payments/view",
  RECEIVED_PAYMENT_CREATE = "/received_payments/new",

  //sms & mail sender

  //ticket sysytem

  TICKET_SYSTEM = "/ticket",
  CREATE_TICKET_SYSTEM = "/ticket/create",
  EDIT_TICKET_SYSTEM = "/ticket/edit",
  VIEW_TICKET_SYSTEM = "/ticket/view",
  DASHBOARD = "/dashboard",
  SMS_MAIL_SENSER = '/bulk_sms_mail_send',
  BULK_EMAIL = '/bulk_email',
  BULK_EMAIL_SENDER = '/bulk_email_send',
  BULK_EMAIL_VIEW = '/bulk_email/view',
  WHATSAPP_MESSAGE_LIST = '/whatsapp_message',
  WHATSAPP_MESSAGE_SENDER = '/whatsapp_message/create',
  WHATSAPP_MESSAGE_EDIT = '/whatsapp_message/edit',
  WHATSAPP_MESSAGE_VIEW = '/whatsapp_message/view',
  BULK_SMS = '/bulk_sms',
  BULK_SMS_SENDER = '/bulk_sms_send',
  BULK_SMS_VIEW = '/bulk_sms/view',


  // incentive ui
  INCENTIVE_SYSTEM = "/incentive",
  REWARD_VALUE = "/reward_value",
  TOTAL_WORKING_DAYS = "/total_working_days",
  TOTAL_WORKING_DAYS_CREATE = "/total_working_days/create",
  TOTAL_WORKING_DAYS_EDIT = "/total_working_days/edit",

  PERMISSION = "/app_permission",

  //Access denied Pages
  ACCESS_DENIED_PAGES = "/accessDenied",

  INCENTIVE_REWARD_SYSTEM = "/incentive_reward_point",


  //Additional expenses reports
  ADDITIONAL_EXPENSES_REPORTS_TABLE = "/additional_expenses_reports",
  ADDITIONAL_EXPENSES_REPORTS_VIEW = "/additional_expenses_reports/view",
  INCENTIVE_REWARD_SYSTEM_VIEW = "/incentive_reward_point/view",
  PAYMENT_SUCCESS = "/payment_success",

  MANUFACTURER_GRID = "/manufacturer",
  MANUFACTURER_EDIT = "/manufacturer/edit",
  MANUFACTURER_VIEW = "/manufacturer/view",
  MANUFACTURER_CREATE = "/manufacturer/create",

  VOUCHER_GRID = "/voucher",
  VOUCHER_EDIT = "/voucher/edit",
  VOUCHER_VIEW = "/voucher/view",
  VOUCHER_CREATE = "/voucher/create",
}

