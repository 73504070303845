import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, Card, CardBody } from "reactstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Option } from "src/models/components/inputWrapperModel";
import {
  Columns,
  LazyTableState,
} from "src/models/components/dataTableWrapperModel";
import DataTableWrapper from "src/components/Grid/DataTableWrapper";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import ColumnAction from "src/components/Common/ColumnAction/ColumnAction";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../Calendar/DeleteModal";
import ArchiveModal from "../../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import Select from "src/components/Common/Select";
import StatusHighlighter from "src/components/Common/StatusHighlighter/StatusHighlighter";
import { CurrencyFormatter, hasFranchiseRoleHandler, saveAsExcelFile, saveDataAsPdf } from "src/helpers/common_helpers";
import { deleteTaxesFromList } from "src/store/taxes/action";
import { partSku } from "src/models/pages/partSkusModel";
import { getPartAutoCompleteList, getPartSkuAutoComplete, getPartSkuList, resetPartskumessage } from "src/store/partSku/action";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import FeatherIcon from "feather-icons-react";
import CustomizeTable from "src/components/Common/CustomizeTable/CustomizeTable";
import SortIcon from "src/components/Common/CustomFilterandSortIcon/SortIcon";
import TooltipMEL from "src/components/UI/Tooltip";
import Barcode from "react-barcode";
import ExportFile from "src/components/Common/ExcelPdf Export/ExportFile";
import FilterIcon from "src/components/Common/CustomFilterandSortIcon/FilterIcon";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import { RootReducerState } from "src/store/reducers";
import { Link } from "react-router-dom";
import { ROUTER } from "src/constants/routes";
import ReactToPrint from "react-to-print";
import BarcodeLabel from "./BarcodeLabel";
import { Dialog } from "primereact/dialog";
import DropdownInput from "src/components/UI/DropdownInput";
import InputCom from "src/components/Common/Input";
import { API } from "src/util/api";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { AutoComplete } from "primereact/autocomplete";
import { customizeTableColumns } from "src/models/components/CustomizeTableColumns";

const partsSkuListConversion = (values: any[]) => {
  const modifeidValue = values.map((eachValue: any) => {
    let data: any = {};
    data["id"] = eachValue.id;
    data["Number"] = eachValue?.number;
    data["Status"] = eachValue?.status;
    data["PartNumber"] = eachValue?.partNumber;
    data["PartDescription"] = eachValue?.partDescription;
    data["PartInBoundNumber"] = eachValue?.partInBoundNumber;
    data["PurchaseOrder"] = eachValue?.purchaseOrder;
    data["AudioBrandName"] = eachValue?.audioBrandName;
    data["InvoiceNumber"] = eachValue?.invoiceNumber;
    data["stockAdjustment"] = eachValue?.stockAdjustment;
    data["Supplier"] = eachValue?.supplier;
    data["barcode"] = eachValue?.number;
    data["labelGenerated"] = eachValue?.labelGenerated;
    data["partIds"] = eachValue?.partId;
    data["partInBoundId"] = eachValue?.partInBoundId;
    data["purchaseOrderId"] = eachValue?.purchaseOrderId;
    data["supplierId"] = eachValue?.supplierId;
    data["audioBrandId"] = eachValue?.audioBrandId;
    data["stockAdjustmentId"] = eachValue?.stockAdjustmentId;
    data["partPrice"] = eachValue?.partPrice;
    data["locationName"] = eachValue?.locationName;
    data["jobPrefix"] = eachValue?.jobPrefix;
    return data;
  });
  return modifeidValue;
};

export default function PartSKUs() {
  const navigate = useNavigate();
  const { sku_id } = useParams();
  const [filter, setFilter] = useState(false);
  const { values, totalRecords } = useSelector(
    (state: RootReducerState) => state.partSkuReducer.partSkuList
  );
  const { error, partList, partSkuNumberList } = useSelector(
    (state: RootReducerState) => state.partSkuReducer
  );
  const modifiedValues: any[] = partsSkuListConversion(values);

  const { showToast } = useToast();
  const loading = useSelector((state: any) => state.partSkuReducer.loading);
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);

  const dispatch = useDispatch();
  const dt = useRef<DataTable<any>>(null);
  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [rowData, setRowData] = useState<partSku | null>(null);
  const [visible, setVisible] = useState(false);
  const filterDetails: DataTableFilterMeta = {
    Number: { value: "", matchMode: "contains" },
    Status: { value: "", matchMode: "contains" },
    PartNumber: { value: "", matchMode: "contains" },
    PartInBoundNumber: { value: "", matchMode: "contains" },
    PartDescription: { value: "", matchMode: "contains" },
    PurchaseOrder: { value: "", matchMode: "contains" },
    Supplier: { value: "", matchMode: "contains" },
    AudioBrandName: { value: "", matchMode: "contains" },
    InvoiceNumber: { value: "", matchMode: "contains" },
    TransistForAdjusment: { value: "", matchMode: "contains" },
    partPrice: { value: "", matchMode: "contains" },
    FranchiseId: { value: sku_id ? sku_id : "", matchMode: "contains" }
  };
  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    // sortField: "",
    // sortOrder: 0,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };
  const [lazyState, setLazyState] = useState(initialLazyState);

  const initialValues = {
    status: "",
  };
  const onFilterClickHandler = () => {
    setFilter((preState) => !preState);
  };

  const numElements = 100;
  const columnToPrintIndex = "Number";
  const elementRefs: any = useRef(null);
  const [labels, setLabels] = useState<any>(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  useEffect(() => {
    const elements = (
      <div ref={elementRefs}>
        {modifiedValues.map((row: any, index: number) => (
          <div key={index} style={{ textAlign: "center" }}>
            {row[columnToPrintIndex] && (
              <Barcode value={row[columnToPrintIndex]} format="CODE128" />
            )}
          </div>
        ))}
      </div>
    );
    setLabels(elements);
  }, [values]);

  useEffect(() => {
    if (error) {
      setVisible(true);
    }
  }, [error]);

  const onPrintRefHandler = async () => {
    setPrintView(true);
    // navigate(ROUTER.PARTS_SKU_PRINT)
    // const printableElement = elementRefs?.current?.innerHTML
    // const printableData = printableElement;
    // console.log("Print Data", printableData)
    // printHTMLContent(printableData)
  };

  const onCreateClickHandler = () => {
    navigate("/createUser");
  };

  const viewHandler = (rowData: partSku) => {
    navigate(`/editCustomer/${rowData["id"]}`);
  };
  const editHandler = (rowData: partSku) => {
    navigate(`/editCustomer/${rowData["id"]}`);
  };
  const deleteHandler = (rowData: partSku) => {
    setDeleteModal(true);
    setRowData(rowData);
  };

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading || pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading, pdfLoading]);

  const actionBodyTemplate = (rowData: partSku) => {
    return (
      <ColumnAction
        isEdit={true}
        isDelete={true}
        isView={true}
        onViewClick={() => viewHandler(rowData)}
        onEditClick={() => editHandler(rowData)}
        onDeleteClick={() => deleteHandler(rowData)}
      />
    );
  };

  const statusBodyTemplate = (rowData: partSku, field: string) => {
    let status = rowData[field] as string;
    return <StatusHighlighter status={status} />;
  };

  const options: Option[] = [
    { label: "Used", value: "Used" },
    { label: "Unused", value: "Unused" },
    { label: "Rejected", value: "Rejected" },
    { label: "Scrapped", value: "Scrapped" },
    { label: "Missing", value: "Missing" },
    { label: "Floating", value: "Floating" },
    { label: "In Transit For Adjustment", value: "TransistForAdjusment" },
    // { label: "In Transit For Invoice", value: "InTransitForInvoice" },
    // { label: "In Transit For Adjustment", value: "In Transit For Adjustment" },
  ];

  const currencyBodyTemplate = (rowData: partSku, field: string) => {
    let amount = rowData[field];
    return <>{CurrencyFormatter({ value: amount })}</>;
  };

  const barcodeTemplate = (rowData: partSku, field: string) => {
    let data = rowData[field] as string;
    return (
      <div id="barcode">
        {data && <Barcode value={data} format="CODE128" width={4} />}
        <div className="text-center max-100-barcode">
          {rowData.PartDescription}
        </div>
      </div>
    );
  };

  const partTemplate = (rowData: any, field: any) => {
    return (
      <>
        {!hasFranchiseRoleHandler() ? (
          <Link to={`${ROUTER.PARTS_VIEW}/${rowData["partIds"]}`}>
            {rowData[field]}
          </Link>
        ) : (
          rowData[field]
        )}
      </>
    );
  };
  const partInboundTemplate = (rowData: any, field: any) => {
    return (
      <>
        {!hasFranchiseRoleHandler() ? (
          <Link to={`${ROUTER.PARTS_INBOUND_VIEW}/${rowData["partInBoundId"]}`}>
            {rowData[field]}
          </Link>
        ) : (
          rowData[field]
        )}
      </>
    );
  };

  const PurchaseOrderTemplate = (rowData: any, field: string) => {
    return (
      <>
        {!hasFranchiseRoleHandler() ? (
          <Link
            to={`${ROUTER.PURCHASE_ORDER_VIEW}/${rowData["purchaseOrderId"]}`}
          >
            {rowData[field]}
          </Link>
        ) : (
          rowData[field]
        )}
      </>
    );
  };

  const supplierBodyTemplate = (rowData: any, field: string) => {
    return (
      <>
        {!hasFranchiseRoleHandler() ? (
          <Link to={`${ROUTER.SUPPLIER_VIEW}/${rowData["supplierId"]}`}>
            {rowData[field]}
          </Link>
        ) : (
          rowData[field]
        )}
      </>
    );
  };

  const AudioBrandTemplate = (rowData: any, field: any) => {
    return (
      <>
        {!hasFranchiseRoleHandler() ? (
          <Link to={`${ROUTER.AUDIO_BRANDS_VIEW}/${rowData["audioBrandId"]}`}>
            {`${rowData[field]} (${rowData?.jobPrefix})`}
          </Link>
        ) : (
          rowData[field]
        )}
      </>
    );
  };

  const StockAdjustmentTemplate = (rowData: any, field: any) => {
    return (
      <Link
        to={
          !hasFranchiseRoleHandler()
            ? `${ROUTER.STOCK_ADJUSTMENT_VIEW}/${rowData["stockAdjustmentId"]}`
            : ""
        }
      >
        {rowData[field] ? `Stock Adjustment(${rowData[field]})` : ""}
      </Link>
    );
  };
  const numberTemplate = (rowData: any) => {
    let isAutoGenerated = rowData?.labelGenerated;
    return (
      <>
        {isAutoGenerated ? (
          <div className="partSkuNumber">{rowData?.Number} <span></span> </div>
        ) : (
          <div>
            <span
              style={{
                maxWidth: "200px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {rowData?.Number}
            </span>
          </div>
        )}
      </>
    );
  };
  const searchParts = (event: any) => {

    dispatch(getPartAutoCompleteList(event.query));
  };
  const searchPartsPartSku = (event: any) => {

    dispatch(getPartSkuAutoComplete(event.query));
  };
  const [partsList, setPartList] = useState([]);
  const [partsSkuList, setPartSkuList] = useState([]);

  const partsTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<div className="autoCompleteFilter">
      <AutoComplete value={options.value} suggestions={partsList} completeMethod={searchParts} onChange={(e: DropdownChangeEvent) => {
        options.filterCallback(e.value, options.index);
        return {};
      }} className="w-full md:w-20rem padding-none" />
    </div>);
  };

  const partsSkuFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<div className="autoCompleteFilter">
      <AutoComplete value={options.value} suggestions={partsSkuList} completeMethod={searchPartsPartSku} onChange={(e: DropdownChangeEvent) => {
        options.filterCallback(e.value, options.index);
        return {};
      }} className="w-full md:w-20rem padding-none" />
    </div>);
  };

  const columns: Columns[] = [
    {
      field: "select",
      sortable: false,
      header: "",
      filter: false,
      showFilterMenu: false,
      selectionMode: "multiple",
      headerStyle: { width: "3rem", whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: true,
      frozen: true,
    },
    {
      field: "Number",
      sortable: true,
      header: "Number",
      filter: true,
      showFilterMenu: true,
      frozen: true,
      headerStyle: { whiteSpace: "nowrap", minWidth: "auto" },
      className:"partSku",
      body: (rowData: any) => numberTemplate(rowData),
      isDefault: true,
      isStatic: true,
      filterElement: partsSkuFilterTemplate,
    },
    {
      field: "Status",
      sortable: true,
      header: "Status",
      filter: true,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "PartNumber",
      sortable: true,
      header: "Part Number",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData: any) => partTemplate(rowData, "PartNumber"),
      isDefault: true,
      isStatic: false,
      filterElement: partsTemplate,
    },
    {
      field: "locationName",
      sortable: false,
      header: "Location",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "partPrice",
      sortable: true,
      header: "Price",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      className: 'align-right',
      isDefault: true,
      isStatic: false,
    },
    {
      field: "PartDescription",
      sortable: true,
      header: "Part Description",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", minWidth: "400px" },
      isDefault: true,
      isStatic: false,
    },

    {
      field: "PartInBoundNumber",
      sortable: false,
      header: "Part Inbound Number",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData: any) => partInboundTemplate(rowData, "PartInBoundNumber"),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "PurchaseOrder",
      sortable: true,
      header: "Purchase Order",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData: any) => PurchaseOrderTemplate(rowData, "PurchaseOrder"),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "Supplier",
      sortable: true,
      header: "Supplier",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", minWidth: "200px" },
      body: (rowData: any) => supplierBodyTemplate(rowData, "Supplier"),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "AudioBrandName",
      sortable: true,
      header: "Audio Brand",
      filter: false,
      showFilterMenu: false, //need filter from backend
      headerStyle: { whiteSpace: "nowrap", minWidth: "150px" },
      body: (rowData: any) => AudioBrandTemplate(rowData, "AudioBrandName"),
      isDefault: true,
      isStatic: false,
    },
    // {
    //   field: "InvoiceNumber",
    //   sortable: false,
    //   header: "Invoice",
    //   filter: false,
    //   showFilterMenu: false,
    //   headerStyle: { whiteSpace: "nowrap", minWidth: "150px" },
    //   isDefault: true,
    //   isStatic: false,
    // },
    {
      field: "stockAdjustment",
      sortable: true,
      header: "References",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData: any) =>
        StockAdjustmentTemplate(rowData, "stockAdjustment"),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "barcode",
      sortable: false,
      header: "Barcode",
      filter: false,
      showFilterMenu: false,
      body: (rowData) => barcodeTemplate(rowData, "Number"),
      headerStyle: { whiteSpace: "nowrap", minWidth: "250px" },
      isDefault: false,
      isStatic: false,
    },

    // {
    //   field: "action",
    //   sortable: false,
    //   header: "Action",
    //   filter: false,
    //   showFilterMenu: false,
    //   body: actionBodyTemplate,
    //   headerStyle: { whiteSpace: "nowrap" },
    // },
  ];

  const [visibleColumns, setVisibleColumns] = useState(
    columns.filter((eachColumn: Columns) => eachColumn.isDefault)
  );

  useEffect(() => {
    if (partList) {
      setPartList(partList?.map((item: any) => item.partNumber));
    }
  }, [partList]);

  useEffect(() => {
    if (partSkuNumberList) {
      setPartSkuList(partSkuNumberList?.map((item: any) => item.number));
    }
  }, [partSkuNumberList]);

  useEffect(() => {
    setVisibleColumns(columns.filter((eachColumn: Columns) => eachColumn.isDefault));
  }, [partsList, partsSkuList]);

  const saveDataAsExcel = (values: any[], fileName: string) => {
    const modifiedColumnDetails = changeColumnDetails(values);
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };

  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values.map((eachDealer) => {
      const {
        id,
        Number,
        PartNumber,
        Status,
        PartDescription,
        PartInBoundNumber,
        PurchaseOrder,
        Supplier,
        AudioBrandName,
        partPrice,
        InvoiceNumber,
        stockAdjustment,
      } = eachDealer;
      let data: any = {};
      data["Number"] = Number;
      data["Status"] = Status;
      data["Part Number"] = PartNumber;
      data["Part Price"] = partPrice;
      data["Part Description"] = PartDescription;
      data["Part Inbound Number"] = PartInBoundNumber;
      data["Purchase Order"] = PurchaseOrder;
      data["Supplier"] = Supplier;
      data["Audio Brand"] = AudioBrandName;
      data["Invoice"] = InvoiceNumber;
      data["stockAdjustment"] = stockAdjustment;
      return { ...data };
    });

    return modifiedColumnDetails;
  };
  const fetchData = async (isPdf: boolean) => {
    let state: any = {
      ...lazyState,
      rows: totalRecords > 5000 ? 5000 : totalRecords < 1 ? 1 : totalRecords,
      first: 0,
      filters: {
        ...lazyState.filters,
      },
    };


    try {
      setPdfLoading(true);
      const response = await API.getPartSkuListAPI(state);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      const modifeidValue = response?.data?.items?.map((eachValue: any) => {
        let data: any = {};
        data["id"] = eachValue.id;
        data["Number"] = eachValue?.number;
        data["Status"] = eachValue?.status;
        data["PartNumber"] = eachValue?.partNumber;
        data["PartDescription"] = eachValue?.partDescription;
        data["PartInBoundNumber"] = eachValue?.partInBoundNumber;
        data["PurchaseOrder"] = eachValue?.purchaseOrder;
        data["AudioBrandName"] = eachValue?.audioBrandName;
        data["InvoiceNumber"] = eachValue?.invoiceNumber;
        data["stockAdjustment"] = eachValue?.stockAdjustment;
        data["Supplier"] = eachValue?.supplier;
        data["barcode"] = eachValue?.number;
        data["labelGenerated"] = eachValue?.labelGenerated;
        data["partIds"] = eachValue?.partId;
        data["partInBoundId"] = eachValue?.partInBoundId;
        data["purchaseOrderId"] = eachValue?.purchaseOrderId;
        data["supplierId"] = eachValue?.supplierId;
        data["audioBrandId"] = eachValue?.audioBrandId;
        data["stockAdjustmentId"] = eachValue?.stockAdjustmentId;
        data["partPrice"] = eachValue?.partPrice;
        data["locationName"] = eachValue?.locationName;
        return data;
      });
      // let resp=await partsSkuListConversion(response?.data?.items)
      if (isPdf) {

        saveDataAsPdf(modifeidValue, columns, "partSkus");
      } else {
        saveDataAsExcel(modifeidValue, "partSkus");
      }
      setPdfLoading(false);
    } catch (error: any) {
      // console.log(error);
      showToast(error.message, { type: "error" });
      setPdfLoading(false);

    } finally {
      setPdfLoading(false);
    }
  };
  const csvClickHandler = () => {
    // saveDataAsExcel(modifiedValues, "partSkus");
    fetchData(false);
  };

  const pdfClickHandler = () => {
    saveDataAsPdf(modifiedValues, columns, "partSkus");
  };

  const validationSchema = Yup.object({});

  const callbackFunction = (lazyState: LazyTableState) => {
    dispatch(getPartSkuList(lazyState));
  };

  const [selectAll, setSelectAll] = useState(false);
  const [selectedPartSku, setSelectedPartSku] = useState<any>([]);

  const onSelectionChange = (event: any) => {
    const value = event.value;
    setSelectedPartSku(value);
    setSelectAll(value.length === totalRecords);
  };

  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;

    if (selectAll) {
      setSelectAll(true);
      setSelectedPartSku(modifiedValues);
    } else {
      setSelectAll(false);
      setSelectedPartSku([]);
    }
  };

  const handleStatusBlur = () => { };

  const onPage = (event: any) => {
    setLazyState(event);
    setSelectedPartSku([]);
    setSelectAll(false);
  };

  const onSort = (event: any) => {

    let filterObject: any = { ...event };
    filterObject["first"] = 0;
    setLazyState(filterObject);
  };
  const onFilter = (event: any) => {
    event["first"] = 0;
    const partDescriptionValue = JSON.parse(
      JSON.stringify(event.filters.PartDescription)
    );
    let obj = event;
    obj.filters["partDescriptionNumber"] = partDescriptionValue;
    setLazyState(obj);
  };

  const handleSubmit = (formValue: any) => {
    setselectedStatus(formValue);
    if (formValue == "TransistForAdjusment") {
      setLazyState((state) => ({
        ...state,

        filters: {
          ...state.filters,
          TransistForAdjusment: { value: "true", matchMode: "contains" },
          Status: {
            ...state.filters.Status,
            value: "",
          },
        },
      }));
    } else {
      setLazyState((state) => ({
        ...state,

        filters: {
          ...state.filters,
          TransistForAdjusment: { value: "", matchMode: "contains" },
          Status: {
            ...state.filters.Status,
            value: formValue,
          },
        },
      }));
    }
    setIsTooltipVisible(false);
  };

  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        },
      },
    }));
  };

  useEffect(() => {
    callbackFunction(lazyState);
  }, [lazyState]);

  const handleDeleteUsers = (rowData: partSku) => {
    let payload = {
      id: rowData["id"],
      lazyState,
    };
    dispatch(deleteTaxesFromList(payload));
    setDeleteModal(false);
    setRowData(null);
    showToast("Part SKUs Deleted Successfully", { type: "success" });
  };

  const archiveHandler = () => {
    setSelectAll(false);
    setSelectedPartSku([]);
    setArchiveModal(false);
  };

  const defaultColumns = {
    ...columns[0],
  };

  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) =>
      selectedColumns.some((sCol: any) => sCol.field === col.field)
    );

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
  };

  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [selectedStatus, setselectedStatus] = useState(null);

  const bulkActionClickHandler = () => {
    if (modifiedValues.length > 0) {
      setSelectAll(true);
      setSelectedPartSku(modifiedValues);
    }
  };

  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedPartSku([]);
  };
  const printStyle: any = {
    display: "flex",
    flexWrap: "wrap",
    width: "100%", // Adjust based on sticker sheet width
    height: "auto", // Let the height adjust based on content
    padding: "10px",
  };
  const componentRef: any = useRef();
  const [partSkuNumbers, setPartSkuNumbers] = useState<any>([]);

  useEffect(() => {
    let numbers = values.map((val: any) => ({
      number: val.number,
      description: val.partDescription,
      partNumber:val.partNumber
    }));
    setPartSkuNumbers(numbers);
  }, [values]);
 
  useEffect(() => {
 
 
    let numbers = selectedPartSku.map((val: any) => ({
      number: val.Number,
      description: val.PartDescription,
      partNumber:val.PartNumber
    }));
    setPartSkuNumbers(numbers);
  }, [selectedPartSku]);

  const [printView, setPrintView] = useState<any>(false);
  const onHide = () => setPrintView(false);
  const printOption = [
    { label: "1", value: "90%" },
    { label: "2", value: "49%" },
  ];
  const [col, setCol] = useState("90%");
  const [height, setHeight] = useState(65);
  const [width, setWidth] = useState(4);
  const [gap, setGap] = useState(1);
  const [margin, setMargin] = useState(0);
  const printyModal = () => {
    return (
      <Dialog
        header="Print Barcode"
        visible={printView}
        maximizable
        className="vw100"
        onHide={onHide}
      >
        <div style={printStyle}>
          <Card style={{ width: "100%" }}>
            <CardBody>
              <Formik
                onSubmit={() => { }}
                initialValues={{
                  col: col,
                  width: width,
                  height: height,
                  gap: gap,
                  margin: margin,
                }}
                enableReinitialize
              >
                {({
                  values,
                  handleChange,
                  touched,
                  errors,
                  handleBlur,
                  resetForm,
                  reset,
                  ...rest
                }: any) => {
                  return (
                    <>
                      <Row>
                        <Col lg={2} className="mb-2">
                          <DropdownInput
                            options={printOption}
                            name="vehicleBrandId"
                            label="No of Column"
                            labelClassName={"d-none"}
                            onChange={(e: any) => {
                              setCol(e.target.value);
                            }}
                            required={false}
                            value={col}
                            onBlur={() => { }}
                            invalid={false}
                          />
                        </Col>
                        <Col lg={2} className="mb-2">
                          <InputCom
                            labelName="Width"
                            inputclassName="form-control"
                            labelClassName="form-Label"
                            type="number"
                            name="width"
                            onBlur={handleBlur}
                            value={values.width}
                            onChange={(e: any) => setWidth(e.target.value)}
                            isRequired={false}
                            invalid={
                              touched["width"] && errors["width"] ? true : false
                            }
                          />
                        </Col>
                        <Col lg={2} className="mb-2">
                          <InputCom
                            labelName="Height"
                            inputclassName="form-control"
                            labelClassName="form-Label"
                            type="number"
                            name="height"
                            onBlur={handleBlur}
                            value={values.height}
                            onChange={(e: any) => setHeight(e.target.value)}
                            isRequired={false}
                            invalid={
                              touched["height"] && errors["height"]
                                ? true
                                : false
                            }
                          />
                        </Col>
                        <Col lg={2} className="mb-2">
                          <InputCom
                            labelName="Horizontal Gap"
                            inputclassName="form-control"
                            labelClassName="form-Label"
                            type="number"
                            name="gap"
                            onBlur={handleBlur}
                            value={values.gap}
                            onChange={(e: any) => setGap(e.target.value)}
                            isRequired={false}
                            invalid={
                              touched["gap"] && errors["gap"] ? true : false
                            }
                          />
                        </Col>
                        <Col lg={2} className="mb-2">
                          <InputCom
                            labelName="Vertical Gap"
                            inputclassName="form-control"
                            labelClassName="form-Label"
                            type="number"
                            name="margin"
                            onBlur={handleBlur}
                            value={values.margin}
                            onChange={(e: any) => setMargin(e.target.value)}
                            isRequired={false}
                            invalid={
                              touched["margin"] && errors["margin"]
                                ? true
                                : false
                            }
                          />
                        </Col>
                        <Col lg={2} className="mb-2 d-flex align-items-center">
                          <ReactToPrint
                            trigger={() => (
                              <Button
                                type="button"
                                className="customize-table-button mr-1 ml-1"
                              >
                                <FeatherIcon icon="printer" className="mr-1" />
                                Print Labels
                              </Button>
                            )}
                            content={() => componentRef.current}
                          />
                        </Col>
                      </Row>
                    </>
                  );
                }}
              </Formik>
            </CardBody>
          </Card>

          <div ref={componentRef}>
            <Row className="d-flex" style={{ gap: `${gap}px` }}>
              {partSkuNumbers?.map((number: any, index: any) => (
                <Col
                  lg={4}
                  key={index}
                  style={{
                    width: col,
                    display: "flex",
                    justifyContent: "start",
                    marginBottom: `${margin}px`,
                  }}
                >
                  <BarcodeLabel
                    number={number.number}
                    description={number.description}
                    partNumber={number.partNumber}
                    Col={col}
                    width={width}
                    height={height}
                  />
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </Dialog>
    );
  };
  const customizeTableColumns: customizeTableColumns[] = columns
    .map((eachColumns: Columns) => {
      let data: any = {};
      data["field"] = eachColumns.field;
      data["value"] = visibleColumns.some(
        (sCol: any) => sCol.field === eachColumns.field
      );
      data["isDisable"] = eachColumns.isStatic;
      data["header"] = eachColumns.header;
      return data;
    })
    .filter(
      (eachColumns: customizeTableColumns) => eachColumns.field != "select"
    );
  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };
  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {

    let orderedSelectedColumns = columns.filter((col) =>
      customizeColumnObject.some((sCol: any) => sCol === col.field)
    );


    // setVisibleColumns([...orderedSelectedColumns]);
    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };
  const clearFilter = () => {
    setLazyState(initialLazyState);
    setselectedStatus(null);
  };

  const header = (
    <div className="table-header-container">
      <div>Part SKU List</div>

      {selectedPartSku.length > 0 && (
        <Button
          type="button"
          className="customize-table-button"
          onClick={onPrintRefHandler}
          style={{
            whiteSpace: "nowrap",
            paddingLeft: "0px",
            marginLeft: "20px",
          }}
        >
          <FeatherIcon icon="printer" className="mr-1" />
          <span className="remove_lables">Print Labels</span>
        </Button>
      )}
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
        <div style={{ display: "flex" }}>
          <ExportFile exportExcel={csvClickHandler} isShowPdf={false} clearFilter={clearFilter} isFilter={true} />
          <TooltipMEL
            title="Select Status"
            position="bottom"
            tooltipVisible={isTooltipVisible}
            setTooltipVisible={setIsTooltipVisible}
          >
            <Dropdown
              value={selectedStatus}
              onChange={(e) => handleSubmit(e.value)}
              options={options}
              optionLabel="label"
              showClear={true}
              placeholder="Select status .."
              filter
              className="search_width w-full md:w-14rem"
            />
          </TooltipMEL>

          <TooltipMEL
            title="Choose fields you want to see in the table"
            position="bottom"
          >
            <Button
              type="button"
              className="customize-table-button"
              id="ScheduleUpdateTooltip"
              onMouseEnter={() => setTooltipOpen(true)}
              onMouseLeave={() => setTooltipOpen(false)}
              data-btn="btn"
              onClick={customizeTableClickHandler}
            >
              <FeatherIcon icon="sliders" className="" />
            </Button>
          </TooltipMEL>
        </div>

        {/* 

        <UncontrolledTooltip
          placement="bottom"
          target="ScheduleUpdateTooltip"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          innerClassName="tooltip-color"
        >
          Choose fields you want to see in the table
        </UncontrolledTooltip> */}
      </div>
    </div>
  );

  const hidePopup = () => {
    setVisible(false);
    dispatch(resetPartskumessage());
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  const paginatorLeft = <div className="d-flex align-items-center gap-2"> <div className="partSkuNumber d-flex align-items-center"><span style={{ padding: "4px" }}></span></div> <div><b> Internal</b>  </div></div>;
  const paginatorRight = <></>;
  return (
    <>
      <div className="page-content" id="stock-music-system">
        {visible && dialogContainer(error)}
        {printView && printyModal()}
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => {
            if (rowData) {
              handleDeleteUsers(rowData);
            }
          }}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        />
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these part sku?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setArchiveModal(false);
            setSelectAll(false);
            setSelectedPartSku([]);
          }}
        />
        {showCustomizeTable && (
          <CustomizeTable
            onCancelHandler={customizeTableCancelHandler}
            onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler}
            columns={customizeTableColumns}
          />
        )}
        {filter && (
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onReset={handleReset}
                enableReinitialize
              >
                {({ values, handleChange }) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row>
                        <Col xs={12} lg={7} id="export-search-clear"></Col>
                        <Col xs={12} lg={5} id="export-search-clear d-flex">
                          <Select
                            label=""
                            name="status"
                            onBlur={handleStatusBlur}
                            value={values.status}
                            onChange={handleChange}
                            options={options}
                            invalid={false}
                          />
                          <Button
                            className="btn btn-primary ms-2"
                            color="primary"
                            type="reset"
                          >
                            Clear{" "}
                          </Button>
                          <Button
                            className="btn btn-primary ms-2"
                            color="primary"
                            type="submit"
                          >
                            Search{" "}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}
        <div style={{ display: "none" }}>{labels}</div>
        <DataTableWrapper
          ref={dt}
          header={header}
          columnDetails={visibleColumns}
          value={modifiedValues}
          lazy
          paginator={true}
          stripedRows={true}
          filterIcon={FilterIcon}
          rowHover={true}
          // filterDisplay="row"
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalRecords}
          loading={loading}
          emptyMessage="No details found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying Part SKUs  {last} records of {totalRecords} in total"
          paginatorLeft={paginatorLeft}
          paginatorRight={paginatorRight}
          selection={selectedPartSku}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
        ></DataTableWrapper>
      </div>
    </>
  );
}
